import React, { useEffect } from 'react';
import { DefaultPage } from '../components/Layout/DefaultPage';
import { useFormHandling } from '../hooks/formUtils';
import { RequestConfigs } from '../api';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { accountRoutesMap, paymentsRoutesMap, routesMap } from '../constants/routes';
import IframeComponent from '../components/auth/Iframe';
import { useLoader } from '../context/LoaderContext';
import styled from 'styled-components';
import { Colors } from '../theme/colors';

export const Process3DNativePage: React.FC = () => {
    const navigate = useNavigate()
    const browser = {
        browserJavaEnabled: window.navigator.javaEnabled(),
        browserJavaScriptEnabled: typeof(window.FileReader) !== "undefined",
        browserAcceptHeader: 'application/json',
        browserLanguage: window.navigator.language,
        browserColorDepth: window.screen.colorDepth.toString(),
        browserScreenWidth: window.screen.width.toString(),
        browserScreenHeight: window.screen.height.toString(),
        browserTZ: new Date().getTimezoneOffset().toString(),
        browserUserAgent: window.navigator.userAgent
    };
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const internalId = queryParams.get('internalId');

    const { handleClick, serverRes } = useFormHandling({ requestType: RequestConfigs.nativeStatus3D, params: { browser, internalId } });

    const {setLoading} = useLoader()
    useEffect(() => {
        handleClick();
        setLoading(false)
    }, []);

    useEffect(() => {
        if(serverRes?.succeeded===false || serverRes?.purchases){
            navigate(accountRoutesMap.pay.to, { state: serverRes });
        } 
    }, [serverRes]);

    return (
        <DefaultPage>
            <MainContainer>
                <IframeComponent url={`${process.env.REACT_APP_API_ENDPOINT}/payments/process-3d?internalId=${internalId}`}/>
            </MainContainer>
            
        </DefaultPage>
    );
};


const MainContainer = styled.div`
display: flex;
  justify-content: center;
  flex-direction:column;
  background-color:${Colors.whiteBackground};
  align-items:center;
  margin-bottom:auto;
  width: 100%;
  height: 500px;
  max-width: 388px; // Fixed width, adjust as needed
`

import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
`;

export const Title = styled.h2`
`

export const P = styled.p`
font-weight: 400;
`

export const List = styled.ul`
  list-style-type:decimal;
`;

export const SubList = styled.ul`
font-weight: 400;
`;

export const ListItem = styled.li<{$isTitle?:boolean}>`
  font-size: ${props=>props.$isTitle?"16px":"14px"};
  font-weight: ${props=>props.$isTitle?"16px":"14px"};
  margin: 5px 0;
`;



export const ListNumbers = styled.ul`
  /* list-style-type:decimal; */
  counter-reset: item;
`;


export const ListNumbersItem = styled.li<{$isTitle?:boolean}>`
  font-size: ${props=>props.$isTitle?"16px":"14px"};
  font-weight: ${props=>props.$isTitle?"600":"400"};
  margin: 10px 0;
  display: block;
  &:before {
    content: counters(item, ".") " "; counter-increment: item 
  }
`;

export const Link = styled(NavLink)`
`


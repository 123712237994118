import styled from "styled-components";

export const ImageContainer = styled.img<{
  paddingtop?: number;
  width?: number | string;
  height?: number | string;
  src: string;
}>`
  padding-top: ${(props) => props.paddingtop}px;
  width: ${(props) =>
    typeof props.width === "number" ? props.width + "px" : props.width};
  height: ${(props) =>
    typeof props.height === "number" ? props.height + "px" : props.height};
  src: url(${(props) => props.src});
  cursor: pointer;
`;

import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { useDeviceType } from "../../context/DeviceContext";
import React from "react";
import { ActionButton } from "../buttons/ActionButton";
import { useSession } from "../../context/SessionContext";
import { accountRoutesMap } from "../../constants/routes";


export const Summary: React.FC= () => {
    const {session} = useSession()
    const totalPrice = session.cart.reduce((total, item) => total + item.price, 0);
    const itemsCount = session.cart.length;
    
    const {isMobile} = useDeviceType()
    

      return (
        <>   
        <Box $isMobile={isMobile}>
            <Title>Summary</Title>
            <Row>
                <Text>Items</Text>
                <Value>{itemsCount}</Value>
            </Row>
            <Row>
                <Text>Total</Text>
                <Value>${totalPrice.toFixed(2)}</Value>
            </Row>
            <Content>
                <ActionButton label="Checkout" route={accountRoutesMap.pay.to} width="100%" height="48px" fontSize="16px" />
            </Content>
        </Box>   
        
        </>
      );
    };

    const Box = styled.div<{$isMobile:boolean}>`
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    background-color: ${Colors.whiteBackground};
    border:${prop=>prop.$isMobile?'none' : '1px solid ' + Colors.fieldBorder} ;
    padding:${prop=>prop.$isMobile?'none' : '20px'} ;
    border-radius: 8px;
    gap:20px;
    `

    const Content = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    gap:10px;
    width: 100%;
    `
    const Row = styled.div`
    display: flex;
    justify-content:space-between;
    align-items: center;
    gap:10px;
    width: 100%;
    `
    const Title=styled.h3`
    align-self: flex-start;
    margin: 0;
    margin-left: 0px;
    `

    const Text=styled.div`
    font-size: 18px;
    color: ${Colors.text};
    `
    const Value=styled.div`
    font-size: 18px;
    font-weight: 500;
    color: ${Colors.blackText};
    `
    
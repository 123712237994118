import { ContentPage } from '../components/Layout/ContentPage';
import { Terms } from '../components/legal/terms';

export const TermsPage = () => {
   

    return (
        <ContentPage title='Terms and Conditions' subTitle='Effective Date: May 1, 2024'>
            <Terms/>
         </ContentPage>
    );
};
import axios, { AxiosInstance, AxiosRequestConfig,AxiosResponse } from 'axios';
import { Account,CartItem,SessionData,PaySource,Billing,Client } from '../context/SessionContext';
import { routesMap } from '../constants/routes';
import { ProdcutType } from '../components/buttons/AddToCartButton';
import { ExpertBuildConfig } from '../components/eaBuilder/newBuildNew';




// Define the type for the data returned by the API
export interface ApiResponse {
    Message:string;
    isLogged:boolean;
    succeeded:boolean;
    cart:CartItem[]
    customExpert:ExpertBuildConfig | null
    account?:Account;
    billing?:Billing;
    client?:Client;
    paySources?:PaySource[];
    errorCode?:number,
    fieldName?:string,
    errorDescription?:string | null;
    paging?:PagingObj;
    signals?:SignalType[];
    courses?:CourseType[];
    experts?:ExpertType[];
    customExperts?:ExpertBuildConfig[];
    purchases?:Purchase[];
    dictionary?:Dictionary;
    auth?:Auth;
    redirect?:string
    statistics?:Statistics;
    expertInfo?:ExpertInfo;
    devServices?:DevService;
    signalsPreference?:SignalsPreference
}

export interface SignalsPreference{
    tradingType: number,
    assetType: number,
    direction: number,
    dailyMax: number,
            
}

export interface DevService{
    id:number;
    name:string;
    price:number;
    currency:string
}


export interface ExpertInfo{
    expertId:number;
    description?:string
    title:string;
    p1Title?:string;
    p1Text?:string;
    p1Bullets?:string[]
    p2Title?:string;
    p2Text?:string;
    p2Bullets?:string[]
    p3Title?:string;
    p3Text?:string;
    p3Bullets?:string[]
    images?:string[]
}

export interface Statistics {
    totalSignals: number;
    winRate: number;
    winPips: number;
    chart: Array<{date: string, profit: number}>;
}

export interface Chart {
    date:string;
    profit:number;
}

export interface Purchase {
    customerId:number;
    orderId:string;
    productId:number;
    productType:ProdcutType;

}

export interface Auth {
    methodURL: string,
    protocolVersion:string,
    correlationId: string,
    transactionId: string,
    threeDSMethodData: string,
    scaIndicator: boolean,
    creq:string,
    acsURL:string,
    status:string

}



export interface Dictionary {
    [key: string]: string | null;
}

export interface CourseType{
    id: number;
    title: string;
    mainDescription: string;
    subDescription: string;
    bullets:string[]
    crmProductId: number;
    level:number;
    rating: number;
    ratingCount:number;
    purchased: number;
    duration:number
    image: string;
    link: string; 
    price: number;
    currency: string
}

export interface ExpertType{
    id: number;
    name: string;
    description: string;
    image: string;
    file: string;
    crmProductId: number;
    class: ExpertClassType;
    tradingType: number;
    rating: number;
    ratingCount:number;
    pl: number;
    drawdown: number;
    downloads: number;
    price: number;
    currency: string
}

export interface SignalType{
    id: number,
    symbol: string,
    tradingType: number,
    assetType: number,
    direction: number,
    disableReason: number,
    openPrice: number,
    sl: number,
    tp1: number,
    tp2: number,
    title: string,
    summary: string,
    symbolImage: string,
    symbolDescription: string,
    digits: string,
    time: string,
    chartImage: string,
    chartTCImage: string,
    triggerPrice: number,
    pl: number,
}

export interface PagingObj {
    totalRecords: number;
    totalPages: number;
    current: number;
    records: string;
}

export enum ExpertClassType {
    Standard=1,
    Gold=2,
    Prime=3,
}

export enum TradingType {
    Scalping=1,
    DayTrading=2,
    Swing=3,
}

export enum PlatfromType {
    MT4=1,
    MT5=2,
}



// Create a centralized Axios instance
const apiInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT, // Specify your base URL
    withCredentials :true
    // You can add other default configurations here
});

enum Method {
    get = "GET",
    post = "POST"
}
export interface RequestConfig {
    url: string;
    method: Method,
}


export interface ApiRequest{
    requestType:RequestConfig
    data?:object,
    params?:object
    secondTry?:boolean
}



export const RequestConfigs =  {
    createSession: {
        url: "/create-session",
        method: Method.post
    },
    login: {
        url: "/login",
        method: Method.post
    },
    deleteAccount: {
        url: "/delete-account",
        method: Method.post
    },
    socialAuth: {
        url: "/social-auth",
        method: Method.post
    },
    dictionary: {
        url: "/dictionary",
        method: Method.get
    },
    registerFcm: {
        url: "/register-fcm",
        method: Method.post
    },
    signup: {
        url: "/signup",
        method: Method.post
    },
    logout: {
        url: "/client/logout",
        method: Method.post
    },
    signalsHistory: {
        url: "/get-signals-history",
        method: Method.get
    },
    signals: {
        url: "/client/get-signals",
        method: Method.get
    },
    checkout: {
        url: "/client/checkout",
        method: Method.get
    },
    addToCart: {
        url: "/add-to-cart",
        method: Method.post
    },
    removeFromCart: {
        url: "/remove-from-cart",
        method: Method.post
    },
    applyPromo: {
        url: "/apply-promo",
        method: Method.post
    },
    newCardPay: {
        url: "/client/pay/new-card",
        method: Method.post
    },
    existingCard: {
        url: "/client/pay/exists-card",
        method: Method.post
    },
    forgotPassword: {
        url: "/forgot-password",
        method: Method.post
    },
    expertMarket: {
        url: "/experts",
        method: Method.get
    },
    expertInfo: {
        url: "/experts/info",
        method: Method.get
    },
    myExperts: {
        url: "/client/my-experts",
        method: Method.get
    },
    coursesMarket: {
        url: "/online-courses",
        method: Method.get
    },
    myCourses: {
        url: "/client/my-courses",
        method: Method.get
    },
    download: {
        url: "/client",
        method: Method.get
    },
    updateAccount: {
        url: "/client/account-info",
        method: Method.post
    },
    signalsPreference: {
        url: "/client/signals-preference",
        method: Method.post
    },
    resetPassword: {
        url: "/client/reset-password",
        method: Method.post
    },
    updateMarketing: {
        url: "/client/update-com-config",
        method: Method.post
    },
    updateMembership: {
        url: "/client/update-membership",
        method: Method.post
    },
    authPayment: {
        url: "/auth-payment",
        method: Method.post
    },

    fingerPrint: {
        url: "/finger-status",
        method: Method.get
    },

    challenge: {
        url: "/challenge-status",
        method: Method.get
    },
    nativeStatus3D: {
        url: "/payments/status-3d",
        method: Method.get
    },
    createCustomExpert: {
        url: "/custom-expert/create",
        method: Method.post
    },
    updateCustomExpert: {
        url: "/custom-expert/update-custom-expert",
        method: Method.post
    },

    getCustomExpert: {
        url: "/client/my-custom-experts",
        method: Method.get
    },
    getDevServices: {
        url: "/custom-expert/dev-services",
        method: Method.get
    },


    
}




export const downloadFile = async (fileUrl:string) => {
    const fileName = fileUrl.split("/").pop() || "test.png"
    try {
      const response = await apiInstance({
        url: fileUrl,
        method: 'GET',
        responseType: 'blob',
      });
  
      const file = new Blob([response.data], { type: 'application/octet-stream' });
      const fileURL = URL.createObjectURL(file);
  
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      URL.revokeObjectURL(fileURL);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
      // Handle error
    }
  };

async function createSession(setSession: React.Dispatch<React.SetStateAction<SessionData>>){
    const res = await apiInstance.post(RequestConfigs.createSession.url,{
        language: process.env.REACT_APP_DEFAULT_LANG,
        token: process.env.REACT_APP_TOKEN,
    })

    const { client,cart,customExpert,isLogged }: ApiResponse = res.data as ApiResponse;

    setSession((prevSession) => ({
        ...prevSession,
        isLogged,
        cart: cart || prevSession.cart,
        customExpert: customExpert,
        client: client || prevSession.client,
    }));
}



export async function apiCall(
    { requestType, data, params, secondTry }: ApiRequest,
    setGlobalLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setSession: React.Dispatch<React.SetStateAction<SessionData>>,
    navigate?:(route:string)=>void,
    disableNav:boolean=false
): Promise<ApiResponse | null> {
    const { method, url } = requestType;
    if(method===Method.get){
        params={...params,...data}
    }
   
    let serverRespond: ApiResponse | null = null;

    try {
        setGlobalLoading(true);

        const config: AxiosRequestConfig = { method, data, params };
        
        const response = await apiInstance.request<ApiResponse>({ url, ...config });
        

        serverRespond = response.data as ApiResponse;

        const { Message, isLogged, cart,customExpert,paySources,billing ,client, account, succeeded }: ApiResponse = response.data as ApiResponse;
    
        if (succeeded) {
            
            setSession((prevSession) => {
                const newSession = {
                    ...prevSession,
                    Message,
                    isLogged,
                    cart: cart || prevSession.cart,
                    customExpert: customExpert,
                    paySources: paySources || prevSession.paySources,
                    billing: billing || prevSession.billing,
                    client: client || prevSession.client,
                };
            
                if (isLogged) {
                    newSession.account = account || prevSession.account;
                } else {
                    delete newSession.account;
                }
            
                return newSession;
            });
        }
    } catch (error: any) {
        
        serverRespond = error.response?.data as ApiResponse;

        if (serverRespond) {
            const { errorCode, Message} = serverRespond;

            if (errorCode === 403 && Message === "err_invalid_session" && !secondTry) {
                await createSession(setSession);
            
                secondTry = true;
                return await apiCall({ requestType, data, params, secondTry }, setGlobalLoading, setSession,navigate);
            } else if (errorCode === 403 && Message === "need to be logged in") {
                setSession((prevSession) => ({
                    ...prevSession,
                    account: null,
                    nextScreen:window.location.pathname
                }));
                if(navigate)
                    navigate(routesMap.login.to)
            }
        }
    }
    setGlobalLoading(false);
    return serverRespond;
}

import Logo from "../assets/images/brand/logo.svg";
import DarkLogo from "../assets/images/brand/darkLogo.svg";
import {ReactComponent as Twitter} from "../assets/images/logos/twitter.svg";
import {ReactComponent as Facebook} from "../assets/images/logos/facebook.svg";
import {ReactComponent as Instagram} from "../assets/images/logos/instagram.svg";
import Github from "../assets/images/logos/github.svg";
import MainBackground from "../assets/images/brand/mainBackground.svg";
import Cart from "../assets/images/icons/cart.svg";
import CartDark from "../assets/images/icons/cart-empty-dark.svg";
import FullCart from "../assets/images/icons/full-cart.svg";
import FullCartDark from "../assets/images/icons/cart-dark.svg";
import App from "../assets/images/images/app.png";
import Mail from "../assets/images/icons/mail.svg";
import Burger from "../assets/images/icons/burger.svg";
import X from "../assets/images/icons/x.svg";
import Up from "../assets/images/icons/up.svg";
import Down from "../assets/images/icons/down.svg";
import Avatar from "../assets/images/images/avatar.svg";
import ProfileArrow from "../assets/images/icons/profileArrow.svg";
import ProfileArrowDark from "../assets/images/icons/profile-arrow-dark.svg";
import Error from "../assets/images/icons/error.svg";
import Success from "../assets/images/icons/success.svg";
import Eye from "../assets/images/icons/eye.svg";
import Google from "../assets/images/icons/google.svg";
import Apple from "../assets/images/icons/apple.svg";
import Exclamation from "../assets/images/icons/exclamation.svg";
import Search from "../assets/images/icons/search.svg";
import RedDown from "../assets/images/icons/red-down.svg";
import GreenUp from "../assets/images/icons/green-up.svg";
import LossStamp from "../assets/images/icons/loss-stamp.svg";
import WinStamp from "../assets/images/icons/win-stamp.svg";
import {ReactComponent as Bars} from "../assets/images/icons/bars.svg";
import Filter from "../assets/images/icons/filter.svg";
import ApplePay from "../assets/images/icons/apple-pay.svg";
import GooglePay from "../assets/images/icons/google-pay.svg";
import RightVector from "../assets/images/icons/right-vector.svg";
import Curve from "../assets/images/images/curve.png";
import Rise185 from "../assets/images/images/rise185.png";
import Rise685 from "../assets/images/images/rise685.png";
import Ethereum from "../assets/images/icons/ethereum.svg";
import Bitcoin from "../assets/images/icons/bitcoin.svg";
import Check from "../assets/images/icons/check.svg";
import RightArrow from "../assets/images/icons/next.svg";
import AppStore from "../assets/images/images/appStore.svg";
import GooglePlay from "../assets/images/images/googlePlay.svg";
import Work from "../assets/images/images/work.svg";
import Machine from "../assets/images/images/ai.png";
import Arrow from "../assets/images/icons/arrow.svg";
import SmallCurve from "../assets/images/images/smallCurve.png";
import WriteUp from "../assets/images/icons/writeUp.svg";
import Avatar1 from '../assets/images/images/homepage/1.png'
import Avatar2 from '../assets/images/images/homepage/2.png'
import Avatar3 from '../assets/images/images/homepage/3.png'
import IntroBg from '../assets/images/brand/testimonial-bg.png'
import Star from '../assets/images/images/homepage/star.png'
import Coffee from '../assets/images/images/coffee.png'
import LeftArrow from '../assets/images/images/homepage/left.svg'
import RightArrowWhite from '../assets/images/images/homepage/right.svg'

import Mastercard from "../assets/images/icons/mastercard.svg";
import Visa from "../assets/images/icons/visa.svg";
import Amex from "../assets/images/icons/amex.svg";
import Vsuccess from "../assets/images/icons/v-success.svg";
import StarIcon from "../assets/images/icons/star.svg";
import {ReactComponent as ExpertIcon} from "../assets/images/icons/expert-icon.svg";
import {ReactComponent as Course} from "../assets/images/icons/course.svg";
import PurpleV from "../assets/images/icons/purple-v.svg";
import {ReactComponent as Download} from "../assets/images/icons/download.svg";
import Play from "../assets/images/icons/play.svg";
import {ReactComponent as Member} from "../assets/images/icons/member.svg";
import HomeMan from "../assets/images/images/homepage-man.png";
import GraphWithMan from "../assets/images/brand/graph-with-man.png";
import ExpertBuild from "../assets/images/images/expertBuild.png";
import MobileGraph from "../assets/images/brand/mobile-graph.svg";
import BurgerLight from "../assets/images/icons/burger-light.svg";
import {ReactComponent as EmptyCartIcon} from "../assets/images/icons/empty-cart-icon.svg";
import WhiteLines from "../assets/images/backgrounds/white-line.svg";
import GreyBackground from "../assets/images/backgrounds/grey-background.jpg";
import {ReactComponent as LoginIcon}  from "../assets/images/icons/login-icon.svg";
import Phone from "../assets/images/icons/phone.svg";


const Logos = {
  Logo,
  DarkLogo,
  Twitter,
  Facebook,
  Instagram,
  Github,
};

const Images = {
  Avatar,
  Avatar1,
  Avatar2,
  Avatar3,
  IntroBg,
  Curve,
  Rise185,
  Rise685,
  AppStore,
  GooglePlay,
  Work,
  Machine,
  SmallCurve,
  Star,
  MobileGraph,
  ExpertBuild,
  Coffee,
  App,
  HomeMan,
  GraphWithMan
};

const Backgrounds = {
  MainBackground,
  WhiteLines,
  GreyBackground,
};

const Icons = {
    Cart,
    FullCart,
    Mail,
    Burger,
    X,
    Up,
    Down,
    ProfileArrow,
    Error,
    Success,
    Eye,
    Google,
    Apple,
    Exclamation,
    Search,
    RedDown,
    GreenUp,
    LossStamp,
    WinStamp,
    Bars,
    Filter,
    ApplePay,
    GooglePay,
    RightVector,
    Check,
    Ethereum,
    Bitcoin,
    RightArrow,
    LeftArrow,
    RightArrowWhite,
    Arrow,
    WriteUp,
    Visa,
    Mastercard,
    Amex,
    Vsuccess,
    StarIcon,
    ExpertIcon,
    Course,
    PurpleV,
    Download,
    Play,
    Member,
    BurgerLight,
    CartDark,
    ProfileArrowDark,
    FullCartDark,
    EmptyCartIcon,
    LoginIcon,
    Phone
}

export {
    Images,
    Logos,
    Backgrounds,
    Icons
}





import { ContentPage } from '../components/Layout/ContentPage';
import { Aml } from '../components/legal/aml';

export const AmlPage = () => {
   

    return (
        <ContentPage title='Anti-Money Laundering (AML) Policy' subTitle='Effective Date: May 1, 2024'>
            <Aml/>
         </ContentPage>
    );
};
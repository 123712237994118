import { ContentPage } from '../components/Layout/ContentPage';
import { Privacy } from '../components/legal/privacy';

export const PrivacyPolicyPage = () => {
   

    return (
        <ContentPage title='Privacy Policy' subTitle='Effective Date: May 1, 2024'>
            <Privacy/>
         </ContentPage>
    );
};
import React from 'react'
import { Signup } from '../components/registrations'
import { AuthPage } from '../components/Layout/AuthPage'

export const SignupPage: React.FC = () => {
    return (
        <AuthPage>
            <Signup />
        </AuthPage>
    )
}
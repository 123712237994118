import styled from "styled-components";
import { Colors } from "../../theme/colors";

import { useDeviceType } from "../../context/DeviceContext";

import React from "react";
import { DataProps } from "../data/DataArea";
import { CourseType, ExpertClassType, TradingType, } from "../../api";
import { Stars } from "../generic/Stars";
import { Icons } from "../../constants/Images";
import { Line } from "../generic/Line"
import { AddToCartButton, ProdcutType } from "../buttons/AddToCartButton"
import { BuyNowButton } from "../buttons/BuyNowButton";
import { VideoPlayer } from "./vimeo";
import { useState } from "react";
import { boolean } from "joi";





export const Course: React.FC<DataProps> = ({ data }) => {
  const { isMobile } = useDeviceType()
  const courseData = data as CourseType;
  const [visibile, setIsVisible] = useState<boolean>(false)
  const hasLink = !(!courseData.link)

  const generateBullets = () => {
    const bullets = []
    for (let i in courseData.bullets) {
      bullets.push(<V key={i}><VImage src={Icons.PurpleV} />{courseData.bullets[i]}</V>)
    }
    return bullets

  }

  const handleLink = () => {
    if (hasLink) {
      setIsVisible(true)
    }

  }

  const calcDuration = () => {
    const hours = Math.floor(courseData.duration / 60 / 60)
    const minutes = Math.floor(courseData.duration / 60)
    const hWord = hours > 1 ? "hours" : "hour"
    const mWord = minutes > 1 ? "minutes" : "minute"
    const hsection = hours ? `${hours} ${hWord} and ` : ``
    return hsection + `${minutes} ${mWord}`
  }



  return (
    <>
      <Container>

        <AboveIconContainer $isMobile={isMobile}>
          {hasLink &&
            <AboveIcon onClick={handleLink} $clickable={hasLink} src={Icons.Play} className="above-icon" />
          }
          <ExpertIcon onClick={handleLink} $clickable={hasLink} $isMobile={isMobile} src={process.env.REACT_APP_API_ENDPOINT + courseData.image} />
        </AboveIconContainer>



        {visibile &&

          <VideoPopup>

            <VideoPopupContent className="VideoPopupContent">
              <CloseButton onClick={() => setIsVisible(false)}>X</CloseButton>
              <VideoPlayer url={courseData.link} />
            </VideoPopupContent>

          </VideoPopup>
        }


        <ExpertContainer id="expt-cont">
          <Section className="section" >
            <LeftContainer>
              <MainTitle>
                <SymbolName>{courseData.title}</SymbolName>
              </MainTitle>

            </LeftContainer>

            <RightContainer>
              <MainTitle>
                <SymbolName>{courseData.price}{courseData.currency}</SymbolName>
              </MainTitle>
            </RightContainer>

          </Section>

          <Section className="section">
            <GreyText>{courseData.mainDescription}</GreyText>
          </Section >

          <Section className="section">
            <VPoint>{generateBullets()}</VPoint>
          </Section >

          <Section className="section">
            <GreyText>{courseData.subDescription}</GreyText>
          </Section >


          <SectionLeft>

            <BulletPoint>
              <Stars score={courseData.rating} scoreCount={courseData.ratingCount} />

              <Point>
                <GreyText>{courseData.purchased} purchased</GreyText>
              </Point>
              <Point>
                <GreyText>{calcDuration()} duration</GreyText>
              </Point>


            </BulletPoint>

          </SectionLeft>


          {!courseData.link &&
            <>
              <Line />
              <ButtonsSection>
                <AddToCartButton productType={ProdcutType.courses} itemId={courseData.id} />
                <BuyNowButton productType={ProdcutType.courses} itemId={courseData.id} />
              </ButtonsSection>

            </>
          }
        </ExpertContainer>


      </Container>

    </>
  );
};

const AboveIconContainer = styled.div<{ $isMobile: boolean }>`
  
display: flex;
position: relative; /* Set the parent container to relative positioning */
width: ${(prop => prop.$isMobile ? '100%' : '140px')};
height: ${(prop => prop.$isMobile ? '120px' : '140px')};
justify-content: center;

`;

const AboveIcon = styled.img<{ $clickable: boolean }>`
 cursor: ${(prop => prop.$clickable ? 'pointer' : 'auto')};
 position: absolute;
 top:50px;

`;

const ExpertIcon = styled.img<{ $isMobile: boolean, $clickable: boolean }>`
cursor: ${(prop => prop.$clickable ? 'pointer' : 'auto')};

box-shadow: 0 0 23px  ${Colors.shadow};
border-radius: 8px;
width: ${(prop => prop.$isMobile ? '100%' : '140px')};
height: ${(prop => prop.$isMobile ? '120px' : '140px')};
object-fit: cover;
margin:0;
`

const VideoPopup = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${Colors.darkShadow};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;


  `;

const CloseButton = styled.button`
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: transparent;
    border: none;
    color: ${Colors.whiteBackground};
    font-size: 20px;
    cursor: pointer;
  `;

const VideoPopupContent = styled.div`
  padding: 25px;
  background:${Colors.blackBackground};
  position: relative;
  width: 100%;
  max-width: 800px; /* Set a maximum width if needed */
  max-height: 600px; /* Set a maximum height if needed */
  `;
const BulletPoint = styled.ul`
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
gap: 5px 20px;
padding: 0;
color: ${Colors.text};
`
const Point = styled.li`
margin-left: 10px;
padding: 0;`


const VPoint = styled.ul`
 display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
  gap: 5px 20px;
  margin-left: 20px;
  padding: 0;
  color: ${Colors.text};
`
const V = styled.li`
  margin-left: 10px;
  padding: 0;
  position: relative; /* Required for positioning the custom image */
  list-style-type: none; /* Remove default bullet point */
  line-height: 20px; /* Adjust according to your design */
`

const VImage = styled.img`
 position: absolute;
  left: -25px; /* Adjust the positioning of the custom image */
  top: 50%; /* Adjust the positioning of the custom image */
  transform: translateY(-50%); /* Center vertically */
`



const Container = styled.div`
display: flex;
/* flex-direction: column; */
flex-wrap: wrap;
justify-content:center;
align-items: flex-start;
border: 1px solid ${Colors.fieldBorder};
background-color: ${Colors.whiteBackground};
padding: 20px;
border-radius: 8px;
gap:20px;
`



const Section = styled.div`
display: flex;
justify-content: space-between;
gap:20px;
width: 100%;
`



const SectionLeft = styled.div`
display: flex;
justify-content: flex-start;
gap:20px;
`

const ButtonsSection = styled.div`
display: flex;
justify-content: flex-end;
gap:10px;
`




const LeftContainer = styled.div`
display: flex;
align-items: center;
gap:10px;
`


const RightContainer = styled.div`
padding: 5px;
display: flex;
gap:5px;
`



const SymbolName = styled.div`
`


const GreyText = styled.div`
font-size: 14px;
color: ${Colors.text};
`
const MainTitle = styled.div`
display: flex;
justify-content: center;
gap:5px;
color: ${Colors.blackText};
font-size: 18px;
font-weight: 600;
`




const ExpertContainer = styled.div`
flex: 1;
display: flex;
flex-direction: column;
justify-content: flex-start;
gap:10px;
/* margin-right: 10px; */
`
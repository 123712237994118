import React, { useState } from 'react';
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { useDeviceType } from '../../context/DeviceContext';

interface ImageBrowseProps {
  setIsVisible: (visible: boolean) => void;
  images: string[];
  startIndex: number;
}

export const ImageBrowse: React.FC<ImageBrowseProps> = ({ setIsVisible, images, startIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);
  const {isMobile} = useDeviceType()

  const showPrevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Overlay>
       <CloseButton onClick={() => setIsVisible(false)}>✖</CloseButton>
        <ImageWrapper>
          <Image src={process.env.REACT_APP_API_ENDPOINT+images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
        </ImageWrapper>
        { images.length>1 &&
          <ButtonsContainer>
          <PrevButton onClick={showPrevImage}>◀</PrevButton>
          <NextButton onClick={showNextImage}>▶</NextButton>
        </ButtonsContainer>
        }
        
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.90);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1200;
`;



const ImageWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 90%;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;  
  align-items: center;
  width: 100%;
  position: absolute;
  gap: 20px;
  bottom: 10px;
`;

const Button = styled.button`
  background: transparent;
  color: ${Colors.whiteBackground};
  font-size: 20px;
  border: none;
  padding: 0;
  cursor: pointer;
  &:hover {
    color: ${Colors.primary};
  }
`;

const PrevButton = styled(Button)`
  margin-left: 10px;
`;

const NextButton = styled(Button)`
  margin-right: 10px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 30px;
`;

export default ImageBrowse;

import { Icons, Images } from "../../../constants/Images";
import { Colors } from "../../../theme/colors";
import { Image } from "../../Image";
import { KTSpan, KTText } from "../../Text";
import { ArrowButton } from "../../buttons/ArrowButton";
import { ElevateContainer } from "../index.style";
import { useNavigate } from 'react-router-dom';
import {
  CheckItem,
  CheckList,
  ElevateWrapper,
  ImageContainer,
  RightContainer,
  TopDesText,
  BackgroundCircle
} from "./index.style";
import { routesMap } from "../../../constants/routes";
import { useDictionary } from "../../../context/DictionaryContext";
import { BackgroundCube, ImgFront } from "../Unlock/index.style";

const checkData = [
  {
    src: Icons.Check,
    text: "home_ea_cb1_text",
    bold: "home_ea_cb1_bold",
  },
  {
    src: Icons.Check,
    text: "home_ea_cb2_text",
    bold: "home_ea_cb2_bold",
  },
  {
    src: Icons.Check,
    text: "home_ea_cb3_text",
    bold: "home_ea_cb3_bold",
  },
  {
    src: Icons.Check,
    text: "home_ea_cb4_text",
    bold: "home_ea_cb4_bold",
  },
  {
    src: Icons.Check,
    text: "home_ea_cb5_text",
    bold: "home_ea_cb5_bold",
  },
];

export const Elevate = () => {
  const navigate = useNavigate()
  const {translate} = useDictionary()
  for(let i in checkData){
    checkData[i].text = translate(checkData[i].text)
    checkData[i].bold = translate(checkData[i].bold)

  }
  return (
    <ElevateContainer>
      <ElevateWrapper>
        <RightContainer>
          <KTText
            fontSize={24}
            fontWeight={500}
            lineheight={28}
            color={`${Colors.blackBackground}`}
          >
            Elevate Your Trading Game with Expert Advisors
          </KTText>
          <TopDesText>
            <KTText
              fontSize={16}
              fontWeight={400}
              lineheight={22}
              color={`${Colors.gray}`}
            >
              {translate("home_ea_p1")}
            </KTText>
            <CheckList>
              {checkData.map((ele, id) => (
                <CheckItem key={id}>
                  <Image width={12} height={9} src={ele.src} />
                  <KTText fontSize={16} lineheight={22} fontWeight={500}>
                    {ele.bold}
                    <KTSpan fontSize={16} lineheight={22} fontWeight={400} color={Colors.gray}>
                      {ele.text}
                    </KTSpan>
                  </KTText>
                </CheckItem>
              ))}
            </CheckList>
            <KTText
              fontSize={16}
              fontWeight={400}
              lineheight={22}
              color={Colors.gray}
            >
              {translate("home_ea_p2")}
            </KTText>
          </TopDesText>
          <ArrowButton
            label= {translate("btn_see_more")}
            color={`${Colors.primary}`}
            fontSize={18}
            fontWeight={500}
            iconHeight={18}
            iconWidth={18}
            handleClick={() => navigate(routesMap.experts.to)}
            iconUrl={Icons.Arrow}
            background={`${Colors.whiteBackground}`}
          />
        </RightContainer>
        <ImageContainer>
            <BackgroundCircle/>
            <ImgFront src={Images.Machine} width={"80%"} height={"100%"} />
          </ImageContainer>
      </ElevateWrapper>
    </ElevateContainer>
  );
};

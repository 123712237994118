interface Map{
    [key:string]:MetaConfig
}

export interface MetaConfig{
    title?:string;
    description:string;
    keywords:string;
    priority?:number;
}
export const metaMap:Map = {
    login:{ 
        description:`Login to ${process.env.REACT_APP_BRAND_NAME} to access your personalized trading toolkit and resources. Secure login for traders to manage their accounts and tools.`,
        keywords:`${process.env.REACT_APP_BRAND_NAME} login, trader login, trading account access, secure trader login, trading tools login`,
        priority:0.8
    },
    signup:{
        priority:0.8,
        description: `Sign up for ${process.env.REACT_APP_BRAND_NAME} to create your personalized trading toolkit and gain access to exclusive resources and tools. Join our community of traders today.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} signup, trader registration, create trading account, join ${process.env.REACT_APP_BRAND_NAME}, trading tools signup`
    },
    forgotPassword:{
        description: `Reset your ${process.env.REACT_APP_BRAND_NAME} password to regain access to your personalized trading toolkit and resources. Follow the steps to securely reset your password.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} forgot password, reset trading account password, recover ${process.env.REACT_APP_BRAND_NAME} account, password reset, trading tools password recovery`
    },
   
    homePage:{ 
        priority:1,
        title:`${process.env.REACT_APP_BRAND_NAME} - Empower Your Trades`,
        description:`Equip yourself with the ultimate trading toolkit for global markets. Elevate your trading game with our advanced Expert Advisors and unlock exclusive trading advantages with our membership program.`,
        keywords:`trading, trading tools,MT4,MT5,Expert Advisors, trading strategies, trading signals, custom EA development, financial markets, trading membership`
    },
    
    tc:{
        priority:0.1,
        description: `Read the Terms and Conditions of ${process.env.REACT_APP_BRAND_NAME}, effective May 1, 2024. Understand the rules and regulations for using our website, products, and services.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} terms and conditions, trading terms, service agreement, user agreement, legal terms`
    }
    ,
    aml:{
        priority:0.1,
        description: `Review the Anti-Money Laundering (AML) Policy of ${process.env.REACT_APP_BRAND_NAME}. Learn about our commitment to preventing money laundering and ensuring compliance with legal regulations.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} AML policy, anti-money laundering, compliance, legal regulations, financial security`
    },
    privacyPolicy: {
        priority:0.1,
        description: `Read the Privacy Policy of ${process.env.REACT_APP_BRAND_NAME} to understand how we collect, use, and protect your personal information. Your privacy is important to us.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} privacy policy, data protection, personal information, data privacy, user privacy`
    },
   
    signalsHistory:{
        priority:0.9, 
        description: `Review the history of trading signals provided by ${process.env.REACT_APP_BRAND_NAME}. Analyze past signals and performance to refine your trading strategies and make informed decisions.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} trading signals history, past trading signals, signal performance, trading strategy analysis, historical trading data`
    },
    liveSignals:{ 
        priority:0.8,
        description: `Stay ahead of the market with ${process.env.REACT_APP_BRAND_NAME}'s real-time trading signals. Receive timely alerts and insights to make informed trading decisions and maximize your profits.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} trading signals, real-time alerts, market insights, trading alerts, profitable trades`
    },
    
    expertsMarket:{ 
        priority:0.8,
        description: `Transform your trading with ${process.env.REACT_APP_BRAND_NAME}'s advanced Expert Advisors. Automate your strategies, eliminate emotional bias, and leverage real-time analysis for effective trading decisions.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} Expert Advisors, trading automation, automated trading, EA trading, trading strategies, real-time market analysis`
    },
    myExperts:{ 
        description: `Transform your trading with ${process.env.REACT_APP_BRAND_NAME}'s advanced Expert Advisors. Automate your strategies, eliminate emotional bias, and leverage real-time analysis for effective trading decisions.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} Expert Advisors, trading automation, automated trading, EA trading, trading strategies, real-time market analysis`
    },
    
    newEaBuild:{
        priority:0.8,
        description: `Unlock the full potential of your trading with ${process.env.REACT_APP_BRAND_NAME}'s custom EA development service. Personalize your trading strategies for MT4 or MT5 and transform them into powerful automated solutions to maximize your profits.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} custom EA, develop trading strategy, MT4 EA development, MT5 EA development, personalized trading strategies, automated trading solutions`
    },
    myEaBuilds:{ 
        description: `Unlock the full potential of your trading with ${process.env.REACT_APP_BRAND_NAME}'s custom EA development service. Personalize your trading strategies for MT4 or MT5 and transform them into powerful automated solutions to maximize your profits.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} custom EA, develop trading strategy, MT4 EA development, MT5 EA development, personalized trading strategies, automated trading solutions`
    },

    faq:{
        priority:0.7,
        description: `Find answers to frequently asked questions about ${process.env.REACT_APP_BRAND_NAME}'s services, products, and policies. Get the information you need to make the most of our trading tools and resources.`,
        keywords: `${process.env.REACT_APP_BRAND_NAME} FAQ, frequently asked questions, trading tools questions, ${process.env.REACT_APP_BRAND_NAME} support, ${process.env.REACT_APP_BRAND_NAME} help`
    },
}
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { useSession } from "../../context/SessionContext";



interface AvatarProps {
  isMobile?:boolean
}

export const AvatarBtn = ({isMobile}:AvatarProps) => {
  const {session} = useSession()

  const generateAvatar = ()=>{
    const size = isMobile?"48px":"36px"
    if(session.account?.photoURL){
      return (
        <AvatarImage src={session.account.photoURL} $size={size}/>
      )
        
      
    } else {
      const firstLetter = session.account?.name[0].toUpperCase() || 'b'
      return (
        <AvatarIcon $size={size}>
              {firstLetter}
        </AvatarIcon>
      )
    }
  }
 

  return (
    <AvatarContianer>
      {generateAvatar()}
    </AvatarContianer>
    
  );
};


const AvatarContianer = styled.div`
 
`;


const AvatarIcon = styled.button<{ $size: string }>`
  color:${Colors.secondary};
  background-color:${Colors.primary};;
  border-radius: 8px;
  justify-content: center;
  padding: 0;
  border: none;
  cursor: pointer;
  height: ${({ $size }) => $size};
  width: ${({ $size }) => $size};
  font-weight: 600;
  font-size: 18px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;



const AvatarImage = styled.img<{ $size: string }>`
height:${({ $size }) => $size};
width: ${({ $size }) => $size};
border-radius: 8px;
justify-content: center;
// padding: 8px 16px;
border: none;

`;

const AccountMenuBtn = styled.img`
  width: 8px;
  height: 4.27px;
`



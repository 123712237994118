
import Joi from "joi";
import { RequestConfigs } from "../../api";
import { routesMap } from "../../constants/routes";
import { Icons } from "../../constants/Images";
import { EaBuild } from "./eaBuild";
import { TextLink } from "../generic/TextLink";   
import { DataLayout } from "../data";
import { FieldType,FieldConfig,TextFieldType } from "../fields";
import { PriceList } from "./priceList";
import { MewBuildNew } from "./newBuildNew";


const fields:FieldConfig[] = [
  {
    type: FieldType.text,
    name: "search",
    label: "Search",
    joiSchema: Joi.string().allow(null, ''),
    typeOptions:{type:TextFieldType.search}
  },
  
]

const registerText = (<>You will need to <TextLink route={routesMap.login.to} fontSize="16px">Login</TextLink> in order to see your custom developed experts. Don’t have account yet? <TextLink route={routesMap.signup.to} fontSize="16px">Signup</TextLink> </>)
const notSureText = (<>You haven't created yet any custom experts. <TextLink route={routesMap.newEaBuild.to}>Build now</TextLink>  to get started!</>)
export const EaBuilder = () => {


  return (
    <>
    <DataLayout 
      fields={fields}
      PublicCustomFilter={PriceList}
      Element={EaBuild} 
      HeadElement={MewBuildNew}
      dataKey="customExperts" 
      publicPage={routesMap.newEaBuild.to}
      privatePage={routesMap.myEaBuilds.to}
      publicName="New EA Build"
      privateName="My Developed EA"
      publicRequest={RequestConfigs.getDevServices}
      privateRequest={RequestConfigs.getCustomExpert}
      registerText={registerText}
      registerTitle="My Developed EA"
      notSureText={notSureText}
      subscribeText="You haven't created yet any custom experts. Build now to get started!"
      icon={Icons.Course}

    />
    </>
    
  );
};


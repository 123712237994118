import React, { useRef } from "react";
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { NavLink } from "react-router-dom";
import { AccountRoutes,accountRoutesMap } from "../../constants/routes";
import { ParentRouteItem } from "../../modules/routesHelper";


export const AccountMenu: React.FC = () => {
  const routes = AccountRoutes;
  const menuRef = useRef<HTMLDivElement>(null);

  const renderRoutes = (routes: ParentRouteItem[]) => {
    return routes.map((route, index) => {
      if (route.to===accountRoutesMap.account.to)
        return null

      return (
        <React.Fragment key={route.name + index}>
          <MenuItem $isActive={window.location.pathname===route.to} to={route.to}>
            {route.name}
          </MenuItem>
        </React.Fragment>
      );
    });
  };

  return <Menu ref={menuRef}>{renderRoutes(routes)}</Menu>;
};

const Menu = styled.div`
  margin: 10px 20px;
`;

const MenuItem = styled(NavLink)<{ $isActive: boolean }>`
  display: block; /* Change to block to ensure each item appears on a new line */
  width: fit-content; /* Adjust the width to fit the content */
  position: relative;
  padding: 10px 0px;
  color: ${Colors.text};
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: ${Colors.primary};
    background-color: ${Colors.whiteBackground};
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${(props) => (props.$isActive ? Colors.primary : "transparent")};
  }
`;

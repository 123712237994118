
import { Line } from "../generic/Line";
import { ActionButton,ButtonStyle } from "../buttons/ActionButton";
import { useDeviceType } from "../../context/DeviceContext";
import { Icon,SubscribeContainer,Body,Text,Buttons,DataContainer,SubText, Svg } from "./index.style";
import { AddToCartButton } from "../buttons/AddToCartButton";
import { ProdcutType } from "../buttons/AddToCartButton";
import { BuyNowButton } from "../buttons/BuyNowButton";

interface SubscribeDataProps{
  icon:any
  text:string
  subText:any
}
export const SubscribeData = ({icon,text,subText}:SubscribeDataProps) => {
  const {isMobile} = useDeviceType()

  
  return (
    <DataContainer id="data-container" $isMobile={isMobile}>        
      <Body>
        <SubscribeContainer>
          <Svg as={icon} width="100px" />
          <Text>{text}</Text>
          <SubText>{subText}</SubText>
          <Line/>
          <Buttons>
            <AddToCartButton itemId={1} productType={ProdcutType.memberProgram}/>
            <BuyNowButton productType={ProdcutType.memberProgram} itemId={1} actionName="Subscribe Now"/>
          </Buttons>    
        </SubscribeContainer>
      </Body>
  </DataContainer>
  );
};



import React from 'react'
import { HomePage } from '../components/homepage'
import { FullPage } from '../components/Layout/FullPage'

export const HomepagePage: React.FC = () => {
    return (
        <FullPage>
            <HomePage/>
        </FullPage>
    )
}
import styled from "styled-components";
import { Colors } from "../../theme/colors";

import { useDeviceType } from "../../context/DeviceContext";

import React, { useEffect, useState } from "react";
import { DataProps } from "../data/DataArea";
import { ExpertType,ExpertClassType,TradingType, PlatfromType, } from "../../api";
import { Stars } from "../generic/Stars";
import { Icons, Images } from "../../constants/Images";
import {Line} from "../generic/Line"
import {AddToCartButton,ProdcutType} from "../buttons/AddToCartButton"
import { BuyNowButton } from "../buttons/BuyNowButton";
import { DownloadButton } from "../buttons/DownloadButton";
import { ArrowButton } from "../buttons/ArrowButton";
import { ExpertBuildConfig } from "./newBuildNew";
import { MT4_INDICATORS,MT5_INDICATORS,DEV_STATUS } from "../../constants/lists";


interface Map {
    [key: string]: any;
}

const mt4indicatorsKeys = Object.keys(MT4_INDICATORS)
const mt4indicatorsValues = Object.values(MT4_INDICATORS)
const mt4indicatorsMap:Map = {}
mt4indicatorsKeys.forEach((key,index)=>{
    mt4indicatorsMap[mt4indicatorsValues[index]] = key
})


const mt5indicatorsKeys = Object.keys(MT5_INDICATORS)
const mt5indicatorsValues = Object.values(MT5_INDICATORS)
const mt5indicatorsMap:Map = {}
mt5indicatorsKeys.forEach((key,index)=>{
    mt5indicatorsMap[mt5indicatorsValues[index]] = key
})



export const EaBuild: React.FC<DataProps>= ({data}) => {
    const {isMobile} = useDeviceType()
    const expertData = data as ExpertBuildConfig;
    const [showPopup,setShowPopup] = useState<boolean>()
    
    const mapIndicators = () => {
        
        const mt4Names = expertData.mt4Indicators?.map(id=>{return mt4indicatorsMap[id]})
        const mt5Names = expertData.mt5Indicators?.map(id=>{return mt5indicatorsMap[id]})
        
        
        return mt4Names?.join(",") || mt5Names?.join(",")

    }

      const devStatus = expertData.status as number
      const getPlatfromTypeName = (value?: PlatfromType | null): string | undefined => {
        return Object.keys(PlatfromType).find((key: string) => PlatfromType[key as keyof typeof PlatfromType] === value);
      }
  
      return (
        <>   
        <Container>
        <ExpertIcon  $isMobile={isMobile} src={Images.ExpertBuild}/> 

            <ExpertContainer id="expt-cont">
                <Section className="section" >
                    <LeftContainer>
                    <MainTitle>           
                        <SymbolName>{expertData.name}</SymbolName>
                    </MainTitle>

                    </LeftContainer>
                    <RightContainer>
                    <MainTitle>           
                        <SymbolName>{DEV_STATUS[devStatus]}</SymbolName>
                    </MainTitle>
                    </RightContainer>

                
                    
                </Section> 

                <Section className="section">
                    {expertData.customLogic &&

                        <Title>
                        <GreyText><b>Logic: </b>{expertData.customLogicDescription}</GreyText>         
                        </Title>

                    }
                    
                </Section > 

                <Section className="section">
                    <Title>
                        <GreyText><b>Indicators: </b>{mapIndicators()}</GreyText>         
                    </Title>
                </Section > 

                
                <ScrollableContainer>
                <BoxSection $isMobile={isMobile}>
                    <Box className="box">
                        <GreyText>PlatformType</GreyText>
                        <BoldText>{getPlatfromTypeName(expertData.platformType)}</BoldText>
                    </Box>
                    <Box className="box">
                        <GreyText>isPrivate</GreyText>
                        <BoldText>{expertData.isPrivate?"Yes":"No"}</BoldText>
                    </Box>

                    <Box className="box">
                        <GreyText>Custom Logic</GreyText>
                        <BoldText>{expertData.customLogic?"Yes":"No"}</BoldText>
                    </Box>
                    
                   
                </BoxSection>
                </ScrollableContainer>
                
            { expertData.file &&
                <>
                <Line></Line>
                <ButtonsSection>
                        <DownloadButton url={expertData.file || "#"} />
                    
                </ButtonsSection>
                </>
                
            }
            
                
            </ExpertContainer>
            <></>
            
        </Container>   
        
        </>
      );
    };



const BulletPoint = styled.ul`
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
gap: 5px 20px;
padding: 0;
color: ${Colors.text};
`
const Point = styled.li`
margin-left: 10px;
padding: 0;`

const Value = styled.div`
display: flex;
gap:10px;
`


const Container = styled.div`
display: flex;
/* flex-direction: column; */
flex-wrap: wrap;
justify-content:center;
align-items: flex-start;
border: 1px solid ${Colors.fieldBorder};
background-color: ${Colors.whiteBackground};
padding: 20px;
border-radius: 8px;
gap:20px;
`

const ChartContainer = styled.div`

    justify-content: flex-start;
    align-content: flex-start;
`



const Section = styled.div`
display: flex;
justify-content: space-between;
gap:20px;
width: 100%;
`

const ScrollableContainer = styled.div`
  overflow-x: auto;
`;

const BoxSection = styled.div<{$isMobile:boolean}>`
  display: flex;
  padding-bottom: 20px;
  flex: 0 0 auto; 
  gap: 0px 20px;
  margin-right:${(prop=>prop.$isMobile?'-40px':'none')} ;
`;

const Box = styled.div`
  flex: 0 0 128px; 
  border-radius: 8px;
  padding: 12px;
  background-color: ${Colors.messageBox};

  
  &:last-child {
    margin-right: 0; /* Remove margin from the last box */
  }
`;

const SectionLeft = styled.div`
display: flex;
justify-content: flex-start;
gap:20px;
`

const ButtonsSection = styled.div`
display: flex;
justify-content: flex-end;
gap:10px;
`




const LeftContainer=styled.div`
display: flex;
align-items: center;
gap:10px;
`


const RightContainer=styled.div`
padding: 5px;
display: flex;
gap:5px;
`

const Title = styled.div`
display: flex;
flex-direction: column;
/* gap:20px; */
align-items: flex-start;
`


const SymbolName = styled.div`
`

const BoldText = styled.div`
font-weight: 600;
font-size: 14px;
margin-top: 5px;
margin-bottom: 5px;

`
const GreyText = styled.div`
font-size: 14px;
color: ${Colors.text};
`
const MainTitle = styled.div`
display: flex;
justify-content: center;
gap:5px;
color: ${Colors.blackText};
font-size: 18px;
font-weight: 600;
`

const SubTitle = styled.div`
color: ${Colors.blackText};
font-size: 14px;
font-weight: 600;
`





const ExpertContainer = styled.div`
flex: 1;
display: flex;
flex-direction: column;
justify-content: flex-start;
gap:10px;
/* margin-right: 10px; */
`




const ExpertIcon = styled.img<{$isMobile:boolean}>`
box-shadow: 0 0 23px  ${Colors.shadow};
/* border-radius: 50% */
border-radius: 8px;
width: ${(prop=>prop.$isMobile?'100%':'140px')};
height: ${(prop=>prop.$isMobile?'120px':'140px')};
object-fit: cover;
margin:0;
`

const DirectionIcon = styled.img`
width: 16px;
`


import { useFormHandling } from "../../hooks/formUtils";
import styled from "styled-components";
import { RequestConfigs } from "../../api";
import { useDeviceType } from "../../context/DeviceContext";
import { PersonalInfo } from "./PersonalInfo";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { accountRoutesMap } from "../../constants/routes";
import { ProdcutType } from "../buttons/AddToCartButton";
import { Colors } from "../../theme/colors";
import { AccountMenu } from "./accountMenu";
import { ResetPassword } from "./ResetPassword";
import { Marketing } from "./Marketing";
import { Membership } from "./Membership";
import { AccountSignalsPreference } from "./SignalsPreference";





export const Account = () => {
  const [req,setReq] = useState(RequestConfigs.newCardPay)
  const { handleInputChange, handleClick, serverRes,formValues } = useFormHandling({ requestType:req})
  const {isMobile} = useDeviceType()
  const navigate=useNavigate()
  useEffect(() => {
    if(window.location.pathname===accountRoutesMap.account.to){
        navigate(accountRoutesMap.personalInfo.to)
    } 

  }, []);

  const getDataContainer = ()=>{
    switch (window.location.pathname){
      case accountRoutesMap.personalInfo.to:
        return (<PersonalInfo/>)
      case accountRoutesMap.resetPassword.to:
        return (<ResetPassword/>)
      case accountRoutesMap.marketingPreference.to:
        return (<Marketing/>)
      case accountRoutesMap.membershipProgram.to:
        return (<Membership/>)
      case accountRoutesMap.signalsPreference.to:
        return (<AccountSignalsPreference/>)

    }
  }
  

  return (
    
    <PageContainer $isMobile={isMobile} className="data-layout">
         
      {
        !isMobile && 
        <MenuContainer $isMobile={isMobile} className="right-container">
          <AccountMenu/>
        </MenuContainer>

      }   
      
       <DataContainer className="data" $isMobile={isMobile}>

        {getDataContainer()}
       </DataContainer>
     
      
       
       

  
   </PageContainer>
   
    
  );
};


const PageContainer = styled.div<{$isMobile:boolean}>`
display: flex;
flex-direction: row;
flex-wrap: wrap;
gap:20px;
width: 100%;
margin-top: ${props=>props.$isMobile? '20px' : 'none'};;
margin-bottom: ${props=>props.$isMobile? '20px' : 'none'};
/* height: 0; */
`;

const DataContainer = styled.div<{$isMobile:boolean}>`
  flex: 1;
   min-width: 250px;
   display: flex;
   flex-direction: column;
   padding: 20px;
   align-items: center;
   margin-left: ${props=>props.$isMobile? '20px' : 'none'};
   margin-right: ${props=>props.$isMobile? '20px' : 'none'};
   border: 1px solid ${Colors.fieldBorder};
   border-radius: 8px;
   background: ${Colors.whiteBackground};
`;


const MenuContainer = styled.div<{$isMobile:boolean}>`
 display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items at the start of the cross axis */
  margin-left: ${props=>props.$isMobile? '20px' : 'none'};
  margin-right: ${props=>props.$isMobile? '20px' : 'none'};
  margin-top: ${props=>props.$isMobile? '20px' : 'none'};
  flex:${props=>props.$isMobile? 1 : '0 0 30%'} ;
  max-width: ${props=>props.$isMobile? 'none' : '320px'};
  min-width: 250px;
  border: 1px solid ${Colors.fieldBorder};
  border-radius: 8px;
  align-self: flex-start; /* Adjust the container's alignment within its parent */
  background: ${Colors.whiteBackground};
`;

const Body = styled.div`
 display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: 100%;
`;


import { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import React from "react";
import { FormComponent } from "../form";
import Joi from "joi";
import { ApiResponse, SignalsPreference } from "../../api";
import { useSession,Account } from "../../context/SessionContext";

import { FieldType, FieldConfig, TextFieldType } from "../fields";
import states from 'states-us';
import { Billing } from "../../context/SessionContext";
import { ActionButton ,ButtonStyle} from "../buttons/ActionButton";
import { useFormHandling } from "../../hooks/formUtils";
import { RequestConfigs } from "../../api";
import {tradingTypeOption,assetTypeOption,directionOption} from "../../constants/lists"


const fields: FieldConfig[] = [
  {
    type: FieldType.multiSelect,
    name: "tradingType",
    label: "Trading Type",
    joiSchema: Joi.array().allow(null),
    typeOptions: { options: tradingTypeOption }
  },
  {
    type: FieldType.multiSelect,
    name: "assetType",
    label: "Assets Types",
    joiSchema: Joi.array().allow(null),
    typeOptions: { options: assetTypeOption }
  },
  {
    type: FieldType.multiSelect,
    name: "direction",
    label: "Direction",
    joiSchema: Joi.array().allow(null),
    typeOptions: { options: directionOption }
  },
  {
    type: FieldType.text,
    name: "dailyMax",
    label: "Max signals Per day",
    joiSchema: Joi.number().required(),
    typeOptions: { type: TextFieldType.number }
  },
]





export const AccountSignalsPreference: React.FC = () => {
  

  const { handleInputChange, handleClick, serverRes,formValues } = useFormHandling({ requestType:RequestConfigs.signalsPreference})

  const { session,setSession } = useSession()

  const defaults = session.account?.signalsPreference

  const [isEditMode,setIsEditMode] = useState(!session.billing?.city)

  const signalsPreference = session.account?.signalsPreference as SignalsPreference


  useEffect(() => {
    if(serverRes!==null && serverRes.signalsPreference){
      const signalsPreference = serverRes.signalsPreference as SignalsPreference
      setSession((prevSession) => ({
        ...prevSession,
        account: prevSession.account?{
          ...prevSession.account,
          signalsPreference:signalsPreference
        } :null,
        
    }))
    }
    
  }, [serverRes]);

  const handleChange = (name: string, value: any, valid: boolean) =>{
   
    handleInputChange(name,value,valid)

  }


 
  
  


  const createFormComponent = () => {
    
    for (let i in fields) {
      const fieldName = fields[i].name as keyof SignalsPreference;
      if (signalsPreference.hasOwnProperty(fieldName) && signalsPreference[fieldName]) {
        fields[i].defaultValue = signalsPreference[fieldName];
      }
      
    }
    return(
      <>
      <FormComponent fields={fields} title="Signals Preferences" onChange={handleChange} serverRes={serverRes} align="flex-start" />
      <ActionButton onClick={handleClick} label="Submit" width="auto" height="48px"/>
      </>
      
    ) 
  }





  return (
    <>
      <Container id="main-sig">
        {createFormComponent()}
      </Container>

    </>
  );
};




const Container = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content:flex-start;
align-items: flex-start;
width: 100%;
border-radius: 8px;
gap:20px;
`

const Head = styled.div`
display: flex;
flex-wrap: wrap;
justify-content:space-between;
align-items: center;
width: 100%;
`

const Title = styled.h3`
margin-top: -5px;
margin-bottom: 0px;
`

const Text = styled.p`
margin-top: -8px;
margin-bottom: -8px;
font-size: 16px;
color:${Colors.text}

`





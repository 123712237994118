import React, { useEffect } from 'react';
import { DefaultPage } from '../components/Layout/DefaultPage';
import { useFormHandling } from '../hooks/formUtils';
import { RequestConfigs } from '../api';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { accountRoutesMap, paymentsRoutesMap, routesMap } from '../constants/routes';

export const Process3DPage: React.FC = () => {
    const navigate = useNavigate()
    const browser = {
        browserJavaEnabled: window.navigator.javaEnabled(),
        browserJavaScriptEnabled: typeof(window.FileReader) !== "undefined",
        browserAcceptHeader: 'application/json',
        browserLanguage: window.navigator.language,
        browserColorDepth: window.screen.colorDepth.toString(),
        browserScreenWidth: window.screen.width.toString(),
        browserScreenHeight: window.screen.height.toString(),
        browserTZ: new Date().getTimezoneOffset().toString(),
        browserUserAgent: window.navigator.userAgent
    };
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const internalId = queryParams.get('internalId');

    const { handleClick, serverRes } = useFormHandling({ requestType: RequestConfigs.authPayment, data: { browser, internalId } });

    useEffect(() => {
        handleClick();
    }, []);

    useEffect(() => {
        if (serverRes?.succeeded && serverRes.auth?.methodURL && serverRes.auth?.threeDSMethodData && internalId) {

            const params = {
                methodURL:serverRes.auth?.methodURL,
                threeDSMethodData:serverRes.auth?.threeDSMethodData,
                internalId:internalId
            }
            const searchParams = new URLSearchParams(params).toString();
            navigate(paymentsRoutesMap.fingerPrint.to+"?"+searchParams)
        } else if(serverRes?.succeeded===false || serverRes?.purchases){
            navigate(accountRoutesMap.pay.to, { state: serverRes });
        }
    }, [serverRes]);

    return (
        <DefaultPage>
            <></>
        </DefaultPage>
    );
};

import React from "react";
import { useLoader } from "../../context/LoaderContext";

interface IframeComponentProps {
  url: string;
  width?: string;
  height?: string;
}

const IframeComponent: React.FC<IframeComponentProps> = ({
  url,
  width = "100%",
  height = "500px",
}) => {
    

  return (
    <div style={{ border: "1px solid #ccc", borderRadius: "8px", overflow: "hidden" }}>
      <iframe
        src={url}
        width={width}
        height={height}
        style={{ border: "none" }}
        title="Iframe Content"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default IframeComponent;

import { useNavigate } from "react-router-dom";
import { Icons, Images } from "../../../constants/Images";
import { Colors } from "../../../theme/colors";
import { Image } from "../../Image";
import { KTText } from "../../Text";
import { ArrowButton } from "../../buttons/ArrowButton";
import { ExcelContainer } from "../index.style";
import { ExcelWrapper, ImageContainer, TextContainer } from "./index.style";
import { routesMap } from "../../../constants/routes";
import { useDictionary } from "../../../context/DictionaryContext";

export const Develop = () => {
  const navigate = useNavigate()
  const {translate} = useDictionary()
  return (
    <ExcelContainer>
      <ExcelWrapper>
        <ImageContainer>
          <Image src={Images.Coffee} width={"100%"} height={"100%"} />
        </ImageContainer>
        <TextContainer>
          <KTText
            fontSize={24}
            fontWeight={500}
            lineheight={28}
            color={`${Colors.blackBackground}`}
          >
            {translate("home_develop_title")}
            
          </KTText>
          <KTText
            fontSize={16}
            fontWeight={400}
            lineheight={22}
            color={`${Colors.gray}`}
          >
             {translate("home_develop_p1")}
          </KTText>
          <ArrowButton
            label= {translate("btn_see_more")}
            color={`${Colors.primary}`}
            fontSize={18}
            fontWeight={500}
            iconHeight={18}
            handleClick={() => navigate(routesMap.eaBuilder.to)}
            iconWidth={18}
            iconUrl={Icons.Arrow}
            background={`${Colors.whiteBackground}`}
          />
        </TextContainer>
      </ExcelWrapper>
    </ExcelContainer>
  );
};

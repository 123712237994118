import React from "react";
import styled from "styled-components";
import { ActionButton } from "../buttons/ActionButton";
import { accountRoutesMap } from "../../constants/routes";
import { useSession } from "../../context/SessionContext";
import { Colors } from "../../theme/colors";




export const StickySummary: React.FC = () => {

    const {session} = useSession()
    const totalPrice = session.cart.reduce((total, item) => total + item.price, 0);
  return (
    <Main>
        
    <StickyButtonsContainer id="StickyButtonsContainer">
        <StickyUserContainer>
            <Text>Total</Text>
            <Value>${totalPrice.toFixed(2)}</Value>
        </StickyUserContainer>
         <ActionButton label="Checkout" route={accountRoutesMap.pay.to} width="500px" height="48px"/>
        
    
</StickyButtonsContainer>
</Main>
  );
};

const StickyButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center; 
  width: 80%;
  bottom: 0px;
  
  /* gap: 20px; */
  background-color: ${Colors.whiteBackground};
`;

const Main = styled.div`
position: fixed;
  display: flex;
  justify-content: space-around;
  align-content: center; /* Align items along the cross axis (vertically) */
  width: 100%;
  bottom: 0px;
  padding-top: 20px;
  padding-bottom: 40px;
  gap: 20px;
  z-index: 5;
  background-color: ${Colors.whiteBackground};
`;

const StickyUserContainer = styled.div`

flex-direction: column;
display: flex;
justify-content: center;
width: 100%;
`;

const Text=styled.div`
font-size: 14px;
color: ${Colors.text};
`
const Value=styled.div`
font-size: 16px;
font-weight: 500;
color: ${Colors.blackText};
`





import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { useState } from "react";
import { Icons } from "../../constants/Images";
import { useDeviceType } from "../../context/DeviceContext";


export interface BoxProps {
    question:string;
  answer:string;
}



export const Box = ({question,answer}: BoxProps) => {
    const [isCollapsed,setIsCollapsed] = useState<boolean>(true)
    const {isMobile} = useDeviceType()
  
  return (
   <BoxContainer $isMobile={isMobile}>
    <TitleContainer>
        <Title>{question}</Title>
        <ToggleIcon src={isCollapsed ? Icons.Up : Icons.Down} alt="Toggle" onClick={()=>{setIsCollapsed(!isCollapsed)}} />

    </TitleContainer>
   {!isCollapsed && 
    <Text>{answer}</Text>
   } 
   
   </BoxContainer>
  );
};


const BoxContainer = styled.div<{$isMobile:boolean}>`
width: ${props=>props.$isMobile?"80%":"60%"};
background-color: ${Colors.whiteBackground};
box-sizing: border-box;
/* height: 150px; */
margin-top:20px;
padding: 20px;
border-radius: 8px;
box-shadow:    0px 4px 4px ${Colors.darkShadow};
border: 1px solid ${Colors.fieldBorder};
`
const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const Title = styled.h3`
/* padding: 20px 10px 0px 20px; */
margin: 0;
color:${Colors.text};
`

const Text = styled.p`
/* padding: 0px 10px 0px 20px; */
font-size: 16px;
color:${Colors.text};
`

const ToggleIcon = styled.img`
  /* padding: 20px 20px 0px 20px; */
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;








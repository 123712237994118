import { SpanContainer, TextContainer } from "./index.style";

interface TextProps {
  children: React.ReactNode;
  fontWeight: number;
  fontSize: number;
  lineheight: number;
  color?: string;
  textalign?: string;
}

export const KTText: React.FC<TextProps> = (props) => {
  const { children, fontWeight, fontSize, lineheight, color, textalign } = props;
  return (
    <TextContainer
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineheight={lineheight}
      color={color}
      textalign={textalign}
    >
      {children}
    </TextContainer>
  );
};

export const KTSpan: React.FC<TextProps> = (props) => {
  const { children, fontWeight, fontSize, lineheight, color } = props;
  return (
    <SpanContainer
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineheight={lineheight}
      color={color}
    >
      {children}
    </SpanContainer>
  );
};

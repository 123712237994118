import { useFormHandling } from "../../hooks/formUtils";
import { RequestConfigs } from "../../api";
import { ActionButton,ButtonStyle } from "./ActionButton";
import { useState } from "react";
import { useSession } from "../../context/SessionContext";
import { ProdcutType } from "./AddToCartButton";
import { useNavigate } from "react-router-dom";
import { routesMap } from "../../constants/routes";
import { useEffect } from "react";
import { ExpertBuildConfig } from "../eaBuilder/newBuildNew";


interface BuyNowButtonProps {
  productType:ProdcutType
  itemId:number
  actionName?:string
}



export const BuyNowButton = ({productType,itemId,actionName="Buy Now" } : BuyNowButtonProps) => {
  const navigate = useNavigate()




  
  const {handleClick,serverRes} = useFormHandling({requestType:RequestConfigs.addToCart,data:{id:itemId,type:productType}})
  
  useEffect(() => {
    if(serverRes!==null){
      navigate(routesMap.cart.to) 
    }
    
  }, [serverRes]);

  
  const buttonClick = ()=>{
    handleClick()
   
  }


  return (
    <ActionButton
      label={actionName}
      width="auto"
      height="36px"
      fontSize="14px"
      fontWeight="500"
      style={ButtonStyle.dark}
      onClick={buttonClick}
    />
  );
};


import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../theme/colors'
import { FieldConfig, FieldType } from '../fields'
import Joi from 'joi'
import { TextFieldType } from '../fields/TextField'
import { useDeviceType } from '../../context/DeviceContext'
import { mt4Indicators, mt5Indicators, platformTypeOption } from '../../constants/lists'
import { ApiResponse, DevService, PlatfromType, RequestConfigs } from '../../api'
import { MT4_INDICATORS, MT5_INDICATORS } from '../../constants/lists'
import { FirstBox } from './firstBox'
import { StepBox } from './stepBox'
import { useSession } from '../../context/SessionContext'
import { useFormHandling } from '../../hooks/formUtils'
import { LastBox } from './lastBox'




// const Mt4IdicatorsOptions = mt4Indicators.map((indicator, index) => { return { name: indicator.name, value: index } })


const Mt4IdicatorsOptions = Object.keys(MT4_INDICATORS).map(key => {
    return { name: key, value: MT4_INDICATORS[key] };
  });

const Mt5IdicatorsOptions = Object.keys(MT5_INDICATORS).map(key => {
return { name: key, value: MT5_INDICATORS[key] };
});

interface Step {
    field: FieldConfig;
    question: string;
}


const steps: Step[] = [
    {
        field: {
            type: FieldType.text,
            name: "name",
            label: "name",
            joiSchema: Joi.string(),
            typeOptions: { type:TextFieldType.text }
        },
        question: "What would be the name of your expert?",
    },
    {
        field: {
            type: FieldType.filterSelect,
            name: "isPrivate",
            label: "",
            joiSchema: Joi.number(),
            typeOptions: { options: [{ name: "Yes", value: 1 }, { name: "No", value: 0 }] }
        },
        question: "Would you preffer to make this expert private",
    },
    {

        field: {
            type: FieldType.filterSelect,
            name: "platformType",
            label: "",
            joiSchema: Joi.number(),
            typeOptions: { options: platformTypeOption }
        },
        question: "Select your prefered platform",
    },
    {
        field: {
            type: FieldType.multiSelect,
            name: "mt4Indicators",
            label: "",
            joiSchema: Joi.array(),
            typeOptions: { options: Mt4IdicatorsOptions }
        },
        question: "Select mt4 indicators you like to use",
    },
    {
        field: {
            type: FieldType.multiSelect,
            name: "mt5Indicators",
            label: "",
            joiSchema: Joi.array(),
            typeOptions: { options: Mt5IdicatorsOptions }
        },
        question: "Select mt5 indicators you like to use",
    },
    {
        field: {
            type: FieldType.filterSelect,
            name: "customLogic",
            label: "",
            joiSchema: Joi.boolean(),
            typeOptions: { options: [{ name: "Yes", value: 1 }, { name: "No", value: 0 }] }
        },
        question: "Is there a custom logic you would like to add ?",
    },
    {
        field: {
            type: FieldType.text,
            name: "customLogicDescription",
            label: "",
            joiSchema: Joi.string(),
            typeOptions: { type: TextFieldType.textArea }
        },
        question: "Please describe your custom logic",
    },

]

export interface ExpertBuildConfig {
    id: number;
    platformType?: PlatfromType | null;
    name?: string | null;
    isPrivate?: number | null;
    mt4Indicators?: number[] | null;
    mt5Indicators?: number[] | null;
    customLogic?: number | null;
    customLogicDescription?: string | null;
    file?: string | null
    status?: number;
    price:number;
    currency:string;
}


interface Pricing {
    [key: string]: any
}

interface NewBuildProps {
    serverRes: any
}


export const MewBuildNew: React.FC<NewBuildProps> = ({ serverRes }) => {
    const {session} = useSession()
    const { isMobile } = useDeviceType()
    const [showPopup, setShowPopup] = useState<boolean>()
    const devServices = serverRes?.devServices as DevService[]

    const [pricing, setPricing] = useState<Pricing>()
    const [price, setPrice] = useState(0)
    const [step, setStep] = useState(0)
    const [buildInitiated, setBuildInitiated] = useState<boolean>(false)
    const [buildConfig, setBuildConfig] = useState<ExpertBuildConfig>()

    const createCustomExpert = useFormHandling({requestType:RequestConfigs.createCustomExpert})

    const updateCustomExpert= useFormHandling({requestType:RequestConfigs.updateCustomExpert,data:buildConfig})

    const sessionCustomExpert = session.customExpert

    useEffect(() => {
        if (devServices) {
            const p = {
                startPrice: devServices.find(service => service.name === "startPrice")?.price,
                indicatorPrice: devServices.find(service => service.name === "indicatorPrice")?.price,
                privatePrice: devServices.find(service => service.name === "privatePrice")?.price,
                customLogicPrice: devServices.find(service => service.name === "customLogicPrice")?.price,
            }
            setPricing(p)
            setPrice(p.startPrice || 0)
        }

    }, [devServices]);

    
    useEffect(() => {
        if (sessionCustomExpert) {
            setBuildConfig(sessionCustomExpert)
        }

    }, [sessionCustomExpert]);


    const startPrice = pricing?.startPrice
    const indicatorPrice = pricing?.indicatorPrice
    const privatePrice = pricing?.privatePrice
    const customLogicPrice = pricing?.customLogicPrice

    const calcPrice = (buildConfig:ExpertBuildConfig) => {
        const totalIndicators = (buildConfig?.mt4Indicators?.length || 0) + (buildConfig?.mt5Indicators?.length || 0)
        setPrice(startPrice + totalIndicators * indicatorPrice + privatePrice * (buildConfig?.isPrivate || 0) + customLogicPrice * (buildConfig?.customLogic || 0))
    }

    const getStarted=()=>{
        if(!session.customExpert){
            createCustomExpert.handleClick()
        }
        setBuildInitiated(true)
    }


    const nextStep = () => {
        setStep(prev => {
            if (steps[prev].field.name==="platformType") { 
                if(buildConfig?.platformType===1)
                    return steps.findIndex(step=>step.field.name==="mt4Indicators");
                else
                    return steps.findIndex(step=>step.field.name==="mt5Indicators");
            } else if (steps[prev].field.name==="customLogic") { 
                if(!buildConfig?.customLogic)
                    return prev+2;
                else
                    return prev+1;
            } else  if (steps[prev].field.name==="mt4Indicators") { 
                return steps.findIndex(step=>step.field.name==="customLogic");
                   
            } else {
                return prev+1;
            }
        });
        updateCustomExpert.handleClick()
    };

    const backStep = () => {
        setStep(prev => {
            let newStep
            if (steps[prev] && steps[prev].field.name==="customLogic") { 
                if(buildConfig?.platformType===1)
                    newStep= steps.findIndex(step=>step.field.name==="mt4Indicators");
                else
                    newStep =  steps.findIndex(step=>step.field.name==="mt5Indicators");
            } else if (steps[prev] && steps[prev].field.name==="mt5Indicators") { 
                newStep = steps.findIndex(step=>step.field.name==="platformType");
                }
             else {
                if(!buildConfig?.customLogic)
                    newStep = prev-2
                else 
                newStep = prev-1;
            }
            if(newStep<0){
                setBuildInitiated(false)
                newStep=0
            }
            return newStep
        });
    }

    const fieldChange = (field:string,value:any) => {
        
        setBuildConfig(prev =>{
            if(prev){
                let newBuild
                if(field==="platformType"){
                    if(value===1)
                        newBuild = {...prev,[field]:value,mt5Indicators:null}
                    else
                    newBuild = {...prev,[field]:value,mt4Indicators:null}
                } else if(field==="customLogic"){
                    if(value!==undefined)
                        newBuild = {...prev,[field]:value,customLogicDescription:null}
                    else
                    newBuild = {...prev,[field]:value}
                } else newBuild =  {...prev,[field]:value}
                calcPrice(newBuild)
                return newBuild 
            }
            
        })
        
    }





    return (
        <>
            <Container>
              
                    {!buildInitiated &&
                        <FirstBox price={pricing?.startPrice} start={getStarted} />
                    }
                    {buildInitiated && steps[step] &&
                        <StepBox
                            question={steps[step].question}
                            field={{...steps[step].field,defaultValue: buildConfig?.[steps[step]?.field?.name as keyof ExpertBuildConfig]}}
                            onFieldChange={fieldChange}
                            next={nextStep}
                            back={backStep}
                            price={price}
                         />
                    }

                    {buildInitiated && !steps[step] &&
                        <LastBox
                            back={backStep}
                         />
                    }
                    
        

            </Container>

        </>
    );
};



const BulletPoint = styled.ul`
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
gap: 5px 20px;
padding: 0;
color: ${Colors.text};
`
const Point = styled.li`
margin-left: 10px;
padding: 0;`

const Value = styled.div`
display: flex;
gap:10px;
`


const Container = styled.div`
display: flex;
/* flex-direction: column; */
flex-wrap: wrap;
justify-content:center;
align-items: flex-start;
border: 1px solid ${Colors.fieldBorder};
background-color: ${Colors.whiteBackground};
padding: 20px;
border-radius: 8px;
gap:20px;
`

const ChartContainer = styled.div`

    justify-content: flex-start;
    align-content: flex-start;
`



const Section = styled.div`
display: flex;
justify-content: space-between;
gap:20px;
width: 100%;
`

const ScrollableContainer = styled.div`
  overflow-x: auto;
`;

const BoxSection = styled.div<{ $isMobile: boolean }>`
  display: flex;
  padding-bottom: 20px;
  flex: 0 0 auto; 
  gap: 0px 20px;
  margin-right:${(prop => prop.$isMobile ? '-40px' : 'none')} ;
`;

const Box = styled.div`
  flex: 0 0 128px; 
  border-radius: 8px;
  padding: 12px;
  background-color: ${Colors.messageBox};

  
  &:last-child {
    margin-right: 0; /* Remove margin from the last box */
  }
`;

const SectionLeft = styled.div`
display: flex;
justify-content: flex-start;
gap:20px;
`

const ButtonsSection = styled.div`
display: flex;
justify-content: flex-end;
gap:10px;
`




const LeftContainer = styled.div`
display: flex;
align-items: center;
gap:10px;
`


const RightContainer = styled.div`
padding: 5px;
display: flex;
gap:5px;
`

const Title = styled.div`
display: flex;
flex-direction: column;
/* gap:20px; */
align-items: flex-start;
`


const SymbolName = styled.div`
`

const BoldText = styled.div`
font-weight: 600;
font-size: 14px;
margin-top: 5px;
margin-bottom: 5px;

`
const GreyText = styled.div`
font-size: 14px;
color: ${Colors.text};
`
const MainTitle = styled.div`
display: flex;
justify-content: center;
gap:5px;
color: ${Colors.blackText};
font-size: 18px;
font-weight: 600;
`

const SubTitle = styled.div`
color: ${Colors.blackText};
font-size: 14px;
font-weight: 600;
`





const ExpertContainer = styled.div`
flex: 1;
display: flex;
flex-direction: column;
justify-content: flex-start;
gap:10px;
/* margin-right: 10px; */
`




const ExpertIcon = styled.img<{ $isMobile: boolean }>`
box-shadow: 0 0 23px  ${Colors.shadow};
/* border-radius: 50% */
border-radius: 8px;
width: ${(prop => prop.$isMobile ? '100%' : '140px')};
height: ${(prop => prop.$isMobile ? '120px' : '140px')};
object-fit: cover;
margin:0;
`

const DirectionIcon = styled.img`
width: 16px;
`


import React from 'react'
import { DefaultPage } from '../components/Layout/DefaultPage'
import { EaBuilder } from '../components/eaBuilder'

export const MyBuildsPage: React.FC = () => {
    return (
        <DefaultPage>
            <EaBuilder />
        </DefaultPage>
    )
}
import React, { useState, useEffect, useRef } from 'react';
import MuxVideo from '@mux/mux-video-react';
import styled from 'styled-components';

interface PlayerProps {
    url: string,
}



const VideoContainer = styled.div`

    max-width: 800px; /* Set a maximum width if needed */
    max-height: 600px; /* Set a maximum height if needed */
`;

export const VideoPlayer:React.FC <PlayerProps>  = ({ url}) => {
    const playbackId = url.split("/").pop()?.replace(".m3u8","");
    const videoRef = useRef<HTMLVideoElement>(null);

 

    return (
        <>
            <VideoContainer>
                
                <MuxVideo
                    ref={videoRef}
                    style={{ width: '100%', height: '100%' }}
                    playbackId={playbackId}
                    metadata={{
                        video_id: 'video-id-123456',
                        video_title: 'Super Interesting Video',
                        viewer_user_id: 'user-id-bc-789',
                    }}
                    streamType="on-demand"
                    controls
                    autoPlay
                    muted
                />
            </VideoContainer>
        </>
        
        
    );
};

import styled from "styled-components";
import { Colors } from "../../theme/colors";
import React from "react";

import { Line } from "../generic/Line";
import { AddToCartButton } from "../buttons/AddToCartButton";
import { ProdcutType } from "../buttons/AddToCartButton";
import { useDictionary } from "../../context/DictionaryContext";
import { Bullets } from "../generic/Bullets";
import { useDeviceType } from "../../context/DeviceContext";
import { Icons, Images } from "../../constants/Images";

import {
  BackgroundCube,
  BannerContainer,
  BannerItem,
  CheckItem,
  
  PlayerContainer,
  UnlockCheckArea,
  UnlockCheckContainer,
  UnlockCheckList,
} from "../homepage/Unlock/index.style";

import { KTText } from "../Text";
import { ArrowButton } from "../buttons/ArrowButton";

export const MembershipPromo: React.FC = () => {
  const { translate } = useDictionary()
  const { isMobile } = useDeviceType()





  return (
    <>
      <PromoTitle>
        <Title>Commonly purchased with:</Title>
      </PromoTitle>
      <Container $isMobile={isMobile}>


      <UnlockContainer>
      <UnlockWrapper>
        <KTText fontSize={18} fontWeight={600} lineheight={20}>
          Unlock Exclusive Trading Advantages with Our Membership Program
        </KTText>

        <KTText
          fontSize={16}
          lineheight={22}
          fontWeight={400}
          color={Colors.gray}
        >
          {translate("membership_p1")}
        </KTText>
        <UnlockCheckArea>
          <UnlockCheckContainer>
            <KTText fontSize={16} lineheight={16} fontWeight={500}>
              Membership Benefits
            </KTText>
            <Bullets rows={[translate("membership_benefit1"),translate("membership_benefit2")]} fontSize="16px"/>
            <KTText
              fontSize={16}
              lineheight={22}
              fontWeight={400}
              color={Colors.gray}
            >
             {translate("membership_p2")}
            </KTText>
            
            
          </UnlockCheckContainer>
          <ImageContainer>
            <BackgroundCube/>
            <ImgFront src={Images.App} width={"60%"} height={"250px"} />
          </ImageContainer>
        </UnlockCheckArea>
        
      </UnlockWrapper>
    </UnlockContainer>

        <Line />
        <Buttons>
          <AddToCartButton itemId={1} productType={ProdcutType.memberProgram} />
        </Buttons>
      </Container>

    </>
  );
};

const ImgFront = styled.img`
position: relative;
z-index: 1;
/* width: 300px; */
margin: auto;
/* @media screen and (max-width: 1024px) {
    width: 320px;
}
@media screen and (max-width: 768px) {
    width: 100%;
} */
`

const UnlockContainer = styled.div`
  /* background-color: ${Colors.grayBackground}; */

  /* @media screen and (max-width: 1280px) {
    padding: 80px 40px 0;
  }

  @media screen and (max-width: 992px) {
    padding: 60px 20px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 24px;
  } */
`;
const UnlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  gap: 28px;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0px;
  }
`;
const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: auto;
    @media screen and (max-width: 1024px) {
        width: 320px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`

const Container = styled.div<{ $isMobile: boolean }>`
background-color: ${Colors.whiteBackground};
   flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${Colors.fieldBorder};
  padding: 20px;
  border-radius: 8px;
  justify-content: flex-start;
  
`;

const Maincontainer = styled.div`
display: flex;


align-items: center;
padding-top: 30px;

gap:20px;
`


const SubscribeContainer = styled.div`
display: flex;
flex-direction: column;
justify-content:flex-start;



`


const Section = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
/* flex-direction: column; */
gap:10px;
margin-top: -15px;
margin-bottom: 20px;
`

const Title = styled.h3`
margin-top: -5px;
margin-bottom: 0px;
color: ${Colors.blackText};
font-size: 18px;
font-weight: 600;
`

const SubTitle = styled.div`
font-size: 14px;
color:${Colors.blackText};
font-weight:500;
`

const Text = styled.div`
font-size: 14px;
color:${Colors.text};
`

const BenefitArea = styled.div`
display: flex;
flex-direction:column;
justify-content: flex-start;
width: 100%;
background-color:${Colors.messageBox};
border-radius: 8px;
padding: 20px;
`

const Image = styled.img`
 position: flex;
 
 width: 150px;
  
`


const VPoint = styled.ul`
 display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
  gap: 10px 20px;
  margin-left: 20px;
  padding: 0;
  color: ${Colors.text};
`

const V = styled.li`
 display: flex;
  flex-wrap: wrap;
  gap:10px;
  margin-left: 10px;
  padding: 0;
  position: relative; /* Required for positioning the custom image */
  list-style-type: none; /* Remove default bullet point */
  line-height: 20px; /* Adjust according to your design */
`


const LeftContainer = styled.div`
display: flex;
align-items: center;
gap:10px;
`

const PromoTitle = styled.div`
margin: 20px 0px;
display: flex;
align-self: flex-start;
justify-content: flex-start;
gap:10px;
`
const RightContainer = styled.div`
padding: 5px;
display: flex;
gap:5px;
`


const Buttons = styled.div`
display: flex;
justify-content: flex-end;
width: 100%;
margin-top: 15px;
gap:10px;
`

import React from 'react'
import { AuthPage } from '../components/Layout/AuthPage'
import { DeleteAccount } from '../components/registrations/DeleteAccount'


import { Header } from '../components/Layout/Header'
import styled from 'styled-components'
import { Backgrounds } from '../constants/Images'
import { Footer } from '../components/Layout/Footer'
import { Colors } from '../theme/colors'
import { useDeviceType } from '../context/DeviceContext'

export const DeleteAccountPage: React.FC = () => {
    const {isMobile} = useDeviceType()
    return (
        <>

<Header />
    <Body>
        <MainContainer>
        <DeleteAccount />
        </MainContainer>
        <Text $isMobile={isMobile}>Deleting your account will remove all of your data, including: First name, Last name, Phone number (if provided), Email, Location and home address (if provided), Payment information (if provided), Trading history, and other data. This request will not delete your Device ID, error messages, and other technical data stored in the app's technical analysis services. The technical data is not linked to you or your identity and does not allow us to track your location or obtain other sensitive information.</Text>
        
    </Body>
    <Footer />
    
       
        
        
        </>
        
    )
}


const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* align-items: center; // Center content vertically */
  width: 100%;
  min-height: 80vh; // Minimum height to cover the full viewport height
  background-image: url(${Backgrounds.MainBackground});
  background-size: cover;
  background-position: center; // Ensure the background is centered

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const Text = styled.p<{$isMobile:boolean}>`
line-height: 16px;
max-width: ${props=>props.$isMobile?'90%':'50%'};
text-align: center;
font-size: 16px;
`

const MainContainer = styled.div`
display: flex;
  justify-content: center;
  flex-direction:column;
  align-items:center;
  margin-top:60px;
  margin-bottom:10px;
  margin-left: 24px;
  margin-right: 24px;
  padding: 32px;
  /* padding-top: 28px; */
  border-radius: 16px;
  background-color:${Colors.whiteBackground};
  width: 100%;
  max-width: 356px; // Fixed width, adjust as needed
`
import styled from "styled-components";

export const EmpowerWrapper = styled.div`
  display: flex;
  padding: 90px 0px 0px 0px;
  max-width: 1200px;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  gap: 32px;
  @media screen and (max-width: 768px) {
    padding-top: 120px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    & > p:nth-child(1) {
      font-size: 38px;
      line-height: 38.13px;
    }
    & > p:nth-child(2) {
      font-size: 20px;
    }
    max-width: 470px;
  }
  @media screen and (max-width: 640px) {
    & > p:nth-child(1) {
      font-size: 24px;
      line-height: 28.13px;
    }
    & > p:nth-child(2) {
      font-size: 16px;
    }
    max-width: 250px;
  }
`;

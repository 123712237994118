import { useFormHandling } from "../../hooks/formUtils";
import { RequestConfigs } from "../../api";
import { ActionButton,ButtonStyle } from "./ActionButton";
import { downloadFile } from "../../api";

import { Icons } from "../../constants/Images";


interface DownloadButtonProps {
  url:string
  
}



export const DownloadButton = ({url } : DownloadButtonProps) => {

    
  const handleDownload = () => {
    downloadFile('client'+url);
  };



  return (
    <ActionButton
      label="download"
      width="110px"
      height="36px"
      fontSize="14px"
      fontWeight="500"
      style={ButtonStyle.light}
      onClick={handleDownload}
      icon={Icons.Download}
    />
  );
};


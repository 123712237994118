import styled from "styled-components";

export const TextContainer = styled.p<{
  fontSize: number;
  fontWeight: number;
  lineheight: number;
  color?: string;
  textalign?: string;
}>`
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineheight}px;
  color: ${(props) => props.color};
  margin: 0px;
  text-align: ${(props) => props.textalign};
  padding: 0px;
`;

export const SpanContainer = styled.span<{
  fontSize: number;
  fontWeight: number;
  lineheight: number;
  color?: string;
}>`
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineheight}px;
  color: ${(props) => props.color};
  margin: 0px;
  padding: 0px;
`;

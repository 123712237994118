import * as React from "react";
import { DesktopHeader } from "./desktop";
import { MobileHeader } from "./mobile";
import GlobalLoader from "../Loader";
import { MetaTags } from "./metatags";

export const Header = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    }

    handleResize(); // Call initially to set the initial state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <MetaTags/>
      <GlobalLoader />
      {isMobile ? <MobileHeader /> : <DesktopHeader />}
    </>
  );
};


import Joi from "joi";
import { RequestConfigs } from "../../api";
import { routesMap } from "../../constants/routes";
import { Icons } from "../../constants/Images";
import { Course } from "./course";
import { TextLink } from "../generic/TextLink";   
import { DataLayout } from "../data";
import { FieldType,FieldConfig,TextFieldType } from "../fields";

const fields:FieldConfig[] = [
  {
    type: FieldType.text,
    name: "search",
    label: "Search",
    joiSchema: Joi.string().allow(null, ''),
    typeOptions:{type:TextFieldType.search}
  },
  {
    type: FieldType.range,
    name: "duration",
    label: "Duration",
    joiSchema: Joi.array(),
    typeOptions:{
      min:0,
      max:1000,
      minFieldName:"durationMin",
      maxFieldName:"durationMax"
    }
  },
  {
    type: FieldType.multiSelect,
    name: "level",
    label: "Level",
    joiSchema: Joi.array(),
    typeOptions:{
      options: [
        { name: "Begginer", value: 1 },
        { name: "Advanced", value: 2 },
      ],
    }
  },
 
  {
    type: FieldType.filterSelect,
    name: "rating",
    label: "Rating",
    joiSchema: Joi.number(),
    typeOptions:{
      options: [
        { name: "1", value: 1 },
        { name: "2", value: 2 },
        { name: "3", value: 3 },
        { name: "4", value: 4 },
        { name: "5", value: 5 },
      ],
    }
  },
]

const registerText = (<>You will need to <TextLink route={routesMap.login.to} fontSize="16px">Login</TextLink> in order to see your online courses. Don’t have account yet? <TextLink route={routesMap.signup.to} fontSize="16px">Signup</TextLink> </>)
const notSureText = (<>You haven't selected any courses yet. <TextLink route={routesMap.coursesMarket.to}>Purchase now</TextLink>  to get started!</>)
export const Courses = () => {


  return (
    <>
    <DataLayout 
      fields={fields} 
      Element={Course} 
      dataKey="courses" 
      publicPage={routesMap.coursesMarket.to}
      privatePage={routesMap.myCourses.to}
      publicName="Courses Market"
      privateName="My Courses"
      publicRequest={RequestConfigs.coursesMarket}
      privateRequest={RequestConfigs.myCourses}
      registerText={registerText}
      registerTitle="My Courses"
      notSureText={notSureText}
      subscribeText="You haven't selected any courses yet. Purchase now to get started!"
      icon={Icons.Course}

    />
    </>
    
  );
};



import styled from 'styled-components';
import { Wrapper,Title,P,List,ListItem,SubList,ListNumbersItem,ListNumbers,Link } from './index.style';
import { useNavigate } from 'react-router-dom';
import { routesMap } from '../../constants/routes';

export const Privacy = () => {
    return (
        <>
            <Wrapper>
                <P>{process.env.REACT_APP_BRAND_NAME} ("we," "us," or "our") respects your privacy and is committed to protecting your personal data. This Privacy Policy outlines how we collect, use, share, and protect your information when you use our website, www.{process.env.REACT_APP_DOMAIN_NAME}, and our products and services.</P>

                <ListNumbers>
                    <ListNumbersItem $isTitle={true}>
                    Information We Collect
                    <ListNumbers>
                    <ListNumbersItem $isTitle={true}>
                        Personal Information
                        <P>We may collect the following personal information from you:</P>
                            <SubList>
                                <ListItem>Contact Information: Name, email address, phone number, mailing address.</ListItem>
                                <ListItem>Account Information: Username, password, account preferences.</ListItem>
                                <ListItem>Payment Information: Credit/debit card details, billing address, payment history.</ListItem>
                                <ListItem>Identification Information: Date of birth, government-issued identification numbers (e.g., passport or driver’s license).</ListItem>
                                <ListItem>Profile Information: Trading experience, investment goals, and other information you provide in your account profile.</ListItem>
                            </SubList>
                        </ListNumbersItem>

                        <ListNumbersItem $isTitle={true}>
                        Usage Information
                        <P>We may collect information about your interactions with our website and services, including:</P>
                            <SubList>
                                <ListItem>Device Information: IP address, browser type, operating system, device type, and device identifiers.</ListItem>
                                <ListItem>Log Information: Pages viewed, links clicked, and other actions taken on our website.</ListItem>
                                <ListItem>Location Information: Approximate location derived from your IP address.</ListItem>
                            </SubList>
                        </ListNumbersItem>

                        <ListNumbersItem $isTitle={true}>
                        Cookies and Similar Technologies
                        <P>We use cookies and similar tracking technologies to collect information about your browsing activities and preferences. For more details, please refer to our Cookie Policy.</P>
                        </ListNumbersItem>


                        </ListNumbers>
                    
                    </ListNumbersItem>


                    <ListNumbersItem $isTitle={true}>
                    How We Use Your Information
                    <ListNumbers>
                        <ListNumbersItem $isTitle={true}>
                        To Provide and Improve Our Services
                        <P>We use your information to:</P>
                            <SubList>
                                <ListItem>Process your transactions and manage your account.</ListItem>
                                <ListItem>Provide, personalize, and improve our products and services.</ListItem>
                                <ListItem>Communicate with you about your account, transactions, and customer support inquiries.</ListItem>
                                <ListItem>Send you important updates, security alerts, and administrative messages.</ListItem>
                                
                            </SubList>
                        </ListNumbersItem>

                        <ListNumbersItem $isTitle={true}>
                        Marketing and Promotional Communications
                        <P>With your consent, we may use your information to:</P>
                            <SubList>
                                <ListItem>Send you marketing emails, newsletters, and other promotional materials.</ListItem>
                                <ListItem>Personalize and deliver targeted advertising based on your interests and browsing behavior.</ListItem>
                            </SubList>
                        </ListNumbersItem>

                        <ListNumbersItem $isTitle={true}>
                        Compliance and Legal Obligations
                        <P>We may use your information to:</P>
                            <SubList>
                                <ListItem>Comply with legal obligations, regulatory requirements, and industry standards.</ListItem>
                                <ListItem>Prevent, detect, and investigate fraud, security breaches, and other potentially prohibited or illegal activities.</ListItem>
                                <ListItem>Enforce our Terms and Conditions and other agreements.</ListItem>
                            </SubList>
                        </ListNumbersItem>

                    </ListNumbers>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                How We Share Your Information
                    <ListNumbers>
                        <ListNumbersItem $isTitle={true}>
                        Service Providers
                        <P>We may share your information with third-party service providers who assist us in operating our website, processing payments, delivering marketing communications, and providing customer support. These service providers are contractually obligated to protect your information and use it only for the purposes for which it was disclosed.</P>
                        </ListNumbersItem>

                        <ListNumbersItem $isTitle={true}>
                        Business Transfers
                        <P>In the event of a merger, acquisition, reorganization, or sale of all or a portion of our assets, your information may be transferred as part of the transaction. We will notify you of any such change in ownership or control of your personal information.</P>
                        </ListNumbersItem>

                        <ListNumbersItem $isTitle={true}>
                        Legal and Regulatory Disclosures
                        <P>We may disclose your information to government authorities, law enforcement agencies, or other third parties if required to do so by law or if we believe that such action is necessary to:</P>
                        <SubList>
                                <ListItem>Comply with legal process or regulatory requirements.</ListItem>
                                <ListItem>Protect our rights, property, and safety, or the rights, property, and safety of others.</ListItem>
                                <ListItem>Investigate and prevent fraud or other illegal activities.</ListItem>
                            </SubList>
                        </ListNumbersItem>

                    </ListNumbers>
                </ListNumbersItem>


                <ListNumbersItem $isTitle={true}>
                Data Security
                    <ListNumbers>
                        <ListNumbersItem $isTitle={true}>
                        Security Measures
                        <P>We implement a variety of security measures to protect your information from unauthorized access, use, alteration, or destruction. These measures include:</P>
                            <SubList>
                                <ListItem>Encryption of sensitive data during transmission and at rest.</ListItem>
                                <ListItem>Access controls to restrict access to your information to authorized personnel only.</ListItem>
                                <ListItem>Regular security assessments and updates to our systems and processes.</ListItem>
                                
                            </SubList>
                        </ListNumbersItem>

                        <ListNumbersItem $isTitle={true}>
                        Your Responsibilities
                        <P>You are responsible for maintaining the confidentiality of your account credentials and for restricting access to your account. Please notify us immediately if you suspect any unauthorized use of your account.</P>
                        </ListNumbersItem>

                    </ListNumbers>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                Data Retention
                <P>We retain your information for as long as necessary to fulfill the purposes for which it was collected, comply with our legal obligations, resolve disputes, and enforce our agreements. The retention period may vary depending on the type of information and the context in which it was collected.</P>
                </ListNumbersItem>


                
                <ListNumbersItem $isTitle={true}>
                Your Rights and Choices
                    <ListNumbers>
                        <ListNumbersItem $isTitle={true}>
                        Access and Correction
                        <P>You have the right to access and update your personal information. You can do this by logging into your account and making the necessary changes. If you need assistance, please contact our customer support team.</P>
                        </ListNumbersItem>

                        <ListNumbersItem $isTitle={true}>
                        Data Portability
                        <P>You have the right to request a copy of your personal information in a structured, commonly used, and machine-readable format. To exercise this right, please contact us using the contact details provided below.</P>
                        </ListNumbersItem>

                        <ListNumbersItem $isTitle={true}>
                        Deletion
                        <P>You have the right to request the deletion of your personal information. Please note that we may need to retain certain information for legal or legitimate business purposes. To request deletion, please contact us using the contact details provided below.</P>
                        </ListNumbersItem>

                        <ListNumbersItem $isTitle={true}>
                        Marketing Communications
                        <P>You can opt out of receiving marketing communications from us by following the unsubscribe instructions included in our emails or by updating your account preferences. Please note that you may still receive administrative and transactional messages from us.</P>
                        </ListNumbersItem>

                        <ListNumbersItem $isTitle={true}>
                        Cookies and Tracking Technologies
                        <P>You can manage your cookie preferences through your browser settings. For more information, please refer to our Cookie Policy.</P>
                        </ListNumbersItem>

                    </ListNumbers>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                International Data Transfers
                <P>{process.env.REACT_APP_BRAND_NAME} is based in the Republic of Cyprus, and your information may be transferred to, stored, and processed in other countries, including countries outside the European Economic Area (EEA). We take appropriate measures to ensure that your information is treated securely and in accordance with this Privacy Policy and applicable data protection laws.</P>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                Children's Privacy
                <P>Our website and services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child, we will take steps to delete such information. If you believe that we have collected information from a child, please contact us using the contact details provided below.</P>
                </ListNumbersItem>


                <ListNumbersItem $isTitle={true}>
                Changes to This Privacy Policy
                <P>We may update this Privacy Policy from time to time to reflect changes in our practices, technologies, legal requirements, or other factors. When we make changes, we will revise the "Effective Date" at the top of this Privacy Policy and post the updated policy on our website. Your continued use of our website and services after the posting of changes constitutes your acceptance of such changes.</P>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                Contact Us
                <P>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</P>
                <ListNumbersItem $isTitle={true}>
                Contact Information
                <P>If you have any questions or concerns about these Terms, please contact us at:</P>
                <P><b>Email:</b>support@{process.env.REACT_APP_DOMAIN_NAME}</P>
                <P><b>Phone:</b>+357-24267217</P>
                <P><b>Address:</b>Strovolou 1, Strovolos 2011, Strovolos, Nicosia 2049, Republic of Cyprus</P>
                </ListNumbersItem>
                </ListNumbersItem>


                
                </ListNumbers>

                
                
                
            </Wrapper>
        </>
        
        
    );
};

const A = styled.div``

import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { useNavigate } from "react-router-dom";

interface ButtonProps {
  route?: string ;
  label: string;
  width?: string;
  height?: string;
  fontSize?:string;
  fontWeight?:string
  style?: ButtonStyle;
  icon?:any;
  onClick?:()=>void
}

export enum ButtonStyle {
  light = 1,
  dark = 2,
  red = 3,
}

export const ActionButton = ({ route="#", label,onClick=()=>{}, width, height,fontSize,fontWeight,icon, style } : ButtonProps) => {
  const navigate = useNavigate()
  const handleClick = async () => {
    onClick()
    navigate(route);
    
  };

  const backgroundcolor = () => {
    switch (style){
      case ButtonStyle.light:
        return Colors.lightPrimary
      case ButtonStyle.red:
        return Colors.error
      default:
        return Colors.primary
    }
    
  };

  const textColor = () => {
    switch (style){
      case ButtonStyle.light:
        return Colors.primary
      case ButtonStyle.red:
        return Colors.whiteBackground
      default:
        return Colors.lightPrimary
    }
  };

  return (
    <SignupButton
      onClick={handleClick}
      width={width}
      height={height}
      color={textColor()}
      background={backgroundcolor()}
      fontSize={fontSize}
      fontWeight={fontWeight}
      
    >
      {icon &&  <Icon as={icon} alt="icon" $iconWidth="14px" $iconHeight="16px" />}
      {label}
    </SignupButton>
  );
};

const Icon = styled.svg<{ $iconWidth?: string; $iconHeight?: string; $fillColor?: string }>`
  width: ${(props) => props.$iconWidth || "20px"};
  height: ${(props) => props.$iconHeight || "20px"};
  fill: ${Colors.primary};
`;

const SignupButton = styled.button<{
  width?: string;
  height?: string;
  color?: string;
  background?: string;
  fontSize?: string;
  fontWeight?:string
}>`
  display: flex;
  align-items: center;
  gap:5px;
  color:${(props) => props.color};
  background-color:${(props) => props.background};
  border-radius: 8px;
  justify-content: center;
  padding: 8px 16px;
  font-size: ${(props) => props.fontSize || "14px"};
  font-weight: ${(props) => props.fontWeight || "400"};
  border: none;
  cursor: pointer;
  height: 36px;
  height: ${(props) => props.height || "36px"};
  width: ${(props) => props.width || "auto"};

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

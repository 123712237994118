import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../theme/colors'
import { ActionButton } from '../buttons/ActionButton'
import { useDeviceType } from '../../context/DeviceContext'
import { FieldConfig, FieldType, Field, TextFieldType } from '../fields'
import { Container, Section, Title, FieldContainer, ExpertContainer, ExpertIcon, LeftContainer, SymbolName, RightContainer, MainTitle, GreyText, ScrollableContainer, Box, BoldText, BoxSection, ButtonsSection } from './index.style'
import { Bullets } from '../generic/Bullets'
import { Line } from '../generic/Line'
import { Images } from '../../constants/Images'
import { useSession } from '../../context/SessionContext'
import { ExpertBuildConfig } from './newBuildNew'
import { MT4_INDICATORS, MT5_INDICATORS } from '../../constants/lists'
import { PlatfromType } from '../../api'
import { DownloadButton } from '../buttons/DownloadButton'
import { AddToCartButton, ProdcutType } from '../buttons/AddToCartButton'
import { BuyNowButton } from '../buttons/BuyNowButton'


export interface FirstBoxProps {
    back: () => void;
}

const bullets = [
    "Custom Automation: Convert your unique trading strategies into automated EAs for MT4 and MT5.",
    "Advanced Indicators: Develop specialized indicators to enhance your market analysis and decision-making.",
    "Risk Management Tools: Implement sophisticated risk management solutions to safeguard your investments.",
    "Precision and Speed: Benefit from precise, rapid trade execution without the influence of emotions.",
]

const Mt4IdicatorsOptions = Object.keys(MT4_INDICATORS).map(key => {
    return { name: key, value: MT4_INDICATORS[key] };
});

const Mt5IdicatorsOptions = Object.keys(MT5_INDICATORS).map(key => {
    return { name: key, value: MT5_INDICATORS[key] };
});


export const LastBox: React.FC<FirstBoxProps> = ({ back }) => {
    const { isMobile } = useDeviceType()
    const { session } = useSession()
    const customExpert = session.customExpert as ExpertBuildConfig

    const mapIndicators = () => {

        const mt4Names = Mt4IdicatorsOptions.filter(option => customExpert.mt4Indicators?.includes(option.value)).map(option => option.name)
        const mt5Names = Mt5IdicatorsOptions.filter(option => customExpert.mt5Indicators?.includes(option.value)).map(option => option.name)


        return mt4Names?.join(",") || mt5Names?.join(",")

    }

    const getPlatfromTypeName = (value?: PlatfromType | null): string | undefined => {
        return Object.keys(PlatfromType).find((key: string) => PlatfromType[key as keyof typeof PlatfromType] === value);
    }

    return (
        // <Container>
        <>

            <ExpertIcon $isMobile={isMobile} src={Images.ExpertBuild} />

            <ExpertContainer id="expt-cont">
                <Section className="section" >
                    <LeftContainer>
                        <MainTitle>
                            <SymbolName>{customExpert.name}</SymbolName>
                        </MainTitle>

                    </LeftContainer>
                    <RightContainer>
                        <MainTitle>
                            <SymbolName>{customExpert.currency}{customExpert.price}</SymbolName>
                        </MainTitle>
                    </RightContainer>



                </Section>

                <Section className="section">
                    {customExpert.customLogic===1 &&

                        <Title>
                            <GreyText><b>Logic: </b>{customExpert.customLogicDescription}</GreyText>
                        </Title>

                    }

                </Section >

                <Section className="section">
                    <Title>
                        <GreyText><b>Indicators: </b>{mapIndicators()}</GreyText>
                    </Title>
                </Section >


                <ScrollableContainer>
                    <BoxSection $isMobile={isMobile}>
                        <Box className="box">
                            <GreyText>PlatformType</GreyText>
                            <BoldText>{getPlatfromTypeName(customExpert.platformType)}</BoldText>
                        </Box>
                        <Box className="box">
                            <GreyText>isPrivate</GreyText>
                            <BoldText>{customExpert.isPrivate ? "Yes" : "No"}</BoldText>
                        </Box>

                        <Box className="box">
                            <GreyText>Custom Logic</GreyText>
                            <BoldText>{customExpert.customLogic ? "Yes" : "No"}</BoldText>
                        </Box>


                    </BoxSection>
                </ScrollableContainer>


                <>
                    <Line></Line>

                    <ButtonsSection>
                        <ActionButton label="Back" onClick={back} />
                        
                        <RightContainer>
                        <AddToCartButton productType={ProdcutType.expertBuilder} itemId={customExpert.id} />
                        <BuyNowButton productType={ProdcutType.expertBuilder} itemId={customExpert.id} />
                        </RightContainer>
                        


                    </ButtonsSection>
                </>




            </ExpertContainer>

        </>
        // <></>

        // </Container>   



    )
}

import { PagingObj } from '../../api';
import { Container,PagesContainer,PageButton,RecordsInfo } from './index.style';

interface PaginationProps {
    pagingObj?: PagingObj;
    onPageChange: (page: number) => void;
}


export const Pagination = ({ pagingObj, onPageChange }: PaginationProps) => {
    const defaultObj = {
        totalRecords:0,
        totalPages:0,
        current:1,
        records:"0-0",
    }
    const {
        totalRecords,
        totalPages,
        current,
        records
    } = pagingObj || defaultObj
    const pageNumbers = [];

    // Calculate range for pages; show max 5 pages centered on current page
    const maxPagesVisible = 5;
    let startPage = Math.max(current - 2, 1);
    let endPage = startPage + maxPagesVisible - 1;

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(endPage - maxPagesVisible + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <Container>
            <PagesContainer>
            <PageButton onClick={() => onPageChange(current - 1)} disabled={current === 1}>
                {'<'}
            </PageButton>
            {pageNumbers.map(number => (
                <PageButton
                    key={number}
                    onClick={() => onPageChange(number)}
                    disabled={number === current}
                    $isActive={number === current}
                >
                    {number}
                </PageButton>
            ))}
            <PageButton onClick={() => onPageChange(current + 1)} disabled={current === totalPages}>
                {'>'}
            </PageButton>
            </PagesContainer>
            
            <RecordsInfo>{`${records} of ${totalRecords} items`}</RecordsInfo>
        </Container>
    );
};




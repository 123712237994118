import { FormComponent } from "../form";
import Joi from "joi";
import { ActionButton } from "../buttons/ActionButton";
import { routesMap,accountRoutesMap } from "../../constants/routes";

import { TextLink } from "../generic/TextLink";
import { TextLine } from "../generic/TextLine";
import { SocialPanel } from "../generic/SocialPanel";
import {TextContainer,Spacer,TCContainer,TextContainerCenter} from "./indexs.style"

import { RequestConfigs } from "../../api";
import { useFormHandling } from "../../hooks/formUtils";

import { FieldType,FieldConfig,TextFieldType } from "../fields";
import { useSession } from "../../context/SessionContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ThankU } from "./ThankU";

const fields:FieldConfig[] = [
  {
    type:FieldType.text,
    name:"emailAddress",
    label:"Email",
    // joiSchema:Joi.string().email({ tlds: { allow: false } }).required(),
    joiSchema:Joi.string(),
    typeOptions:{type:TextFieldType.text}
  },
  {
    type:FieldType.text,
    name:"password",
    label:"Password",
    joiSchema:Joi.string().min(6).required(),
    typeOptions:{type:TextFieldType.password}
  },
]

const DeleteAccount = () => {

  const {handleInputChange,handleClick,serverRes}=useFormHandling({requestType:RequestConfigs.deleteAccount})




  
    return (
    
      <>
      {serverRes?.succeeded && 
        <ThankU title="Delete Account" text="Your Account has successfully deleted" actionText="" actionUrl="#"/>
      }
      {
        !serverRes && 
        <>
        <FormComponent fields={fields} serverRes={serverRes } title="Delete My Account" onChange={handleInputChange}></FormComponent>
      
      <TextContainer>
        <TextLink route={routesMap.forgotPassword.to} fontSize="16px">Forgot Password?</TextLink>
      </TextContainer>

      <ActionButton onClick={handleClick} label="Delete Account" width="100%" height="48px" fontSize="16px" />
      <Spacer/>
      <TextLine width="88%">or</TextLine>
      <Spacer/>
      <SocialPanel/>
      <Spacer/>
      </>
      }
      
      
    </>
      
    );
  };


export {DeleteAccount};

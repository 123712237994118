import React  from "react";
import {
  HomepageContainer,
} from "./index.style";
import { UnlockTrading } from "./Unlock/Unlock";
import { Excel } from "./Excel/Excel";
import { Elevate } from "./Elevate/Elevate";
import { Empower } from "./Empower/Empower";
import { BestTraders } from "./Carousel/Carousel";
import { Space } from "./Unlock/index.style";
import { Develop } from "./Develop/Develop";

export const HomePage: React.FC = () => {

  return (
    <HomepageContainer>
      <Empower />
      
      <Elevate />
      <Develop/>
      {/* <Excel /> */}
      <UnlockTrading />
      <Space />
      <BestTraders />
    </HomepageContainer>
  );
};

import styled from "styled-components";
import { Images } from "../../../constants/Images";
import { Colors } from "../../../theme/colors";

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ArrowArea = styled.div`
  display: flex;
  gap: 12px;
`;

export const Intro = styled.div`
  background-image: url(${Images.IntroBg});
  width: 520px;
  height: 208px;
  background-size: cover;
  border-radius: 16px;
  padding: 20px;
  @media screen and (max-width: 460px) {
    & > img {
        width: 250px;
        top: 50px;
    }    
  }
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  max-width: 220px;
  @media screen and (max-width: 640px) {
    max-width: 170px;
    
  }
`;

export const StarArea = styled.div`
    display: flex;
    gap: 2px;
`;

export const FullName = styled.div`
    display: flex;
    gap: 8px;
`

export const CustomImage = styled.img<{ src: string, width: number, top: number, right: number }>`
  src: url(${(props) => props.src});
  position: absolute;
  width: ${(props) => props.width}px;
  top: ${(props) => props.top}px;
  right: ${(props) => props.right}px;
`;

export const ImageContainer = styled.div`
    background-color: ${Colors.grayBackground};
    width: 100%;
    height: 100%;
    border-radius: 16px;
    contain: content;
`
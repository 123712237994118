

import React from 'react'
import { DefaultPage } from '../components/Layout/DefaultPage'
import { Pay } from '../components/Pay'

export const PayPage: React.FC = () => {
    return (
        <DefaultPage>
            <Pay />
        </DefaultPage>
    )
}
import { FormComponent } from "../form";
import Joi from "joi";
import { ActionButton } from "../buttons/ActionButton";
import { routesMap,accountRoutesMap } from "../../constants/routes";

import { TextLink } from "../generic/TextLink";
import { TextLine } from "../generic/TextLine";
import { SocialPanel } from "../generic/SocialPanel";
import {TextContainer,Spacer,TCContainer,TextContainerCenter} from "./indexs.style"

import { RequestConfigs } from "../../api";
import { useFormHandling } from "../../hooks/formUtils";

import { FieldType,FieldConfig,TextFieldType } from "../fields";
import { useSession } from "../../context/SessionContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const fields:FieldConfig[] = [
  {
    type:FieldType.text,
    name:"emailAddress",
    label:"Email",
    // joiSchema:Joi.string().email({ tlds: { allow: false } }).required(),
    joiSchema:Joi.string(),
    typeOptions:{type:TextFieldType.text}
  },
  {
    type:FieldType.text,
    name:"password",
    label:"Password",
    joiSchema:Joi.string().min(6).required(),
    typeOptions:{type:TextFieldType.password}
  },
]

const Login = () => {
  const {session,setSession} = useSession()
  const {handleInputChange,handleClick,serverRes}=useFormHandling({requestType:RequestConfigs.login})


  const navigate = useNavigate()

  useEffect(() => {
    if(serverRes?.succeeded){
      if(session.nextScreen){ 
        navigate(session.nextScreen)
        setSession((prevSession) => ({
          ...prevSession,
          nextScreen:null
      }));
      } else {
        navigate(routesMap.signals.to)
      }
      
    }
  
  }, [serverRes]);


  const subTitle = (<TextContainerCenter>Don’t have an account?&nbsp;<TextLink hasLine={false} fontSize="16px" route={routesMap.signup.to}>Sign up</TextLink></TextContainerCenter>) 
    return (<>
      <FormComponent fields={fields} serverRes={serverRes } title="Login" subTitle={subTitle} onChange={handleInputChange}></FormComponent>
      
      <TextContainer>
        <TextLink route={routesMap.forgotPassword.to} fontSize="16px">Forgot Password?</TextLink>
      </TextContainer>

      <ActionButton onClick={handleClick} label="Login" width="100%" height="48px" fontSize="16px" />
      <Spacer/>
      <TextLine width="88%">or</TextLine>
      <Spacer/>
      <SocialPanel/>
      <Spacer/>
      
    </>
      
    );
  };


export {Login};

import styled from "styled-components"

export const TextContainer = styled.div`
margin-bottom:20px ;
display: flex;
justify-content: flex-end;
/* align-items: end; */
width: 88%;
`

export const TextContainerCenter = styled.div`
margin-bottom:20px ;
display: flex;
justify-content: center;
width: 88%;
`



export const Spacer = styled.div`
margin: 10px;
`

export const TCContainer = styled.div`
/* margin-bottom:20px ; */
width: 100%;
`
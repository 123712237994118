

import React from 'react'
import { DefaultPage } from '../components/Layout/DefaultPage'
import { Cart } from '../components/cart'

export const CartPage: React.FC = () => {
    return (
        <DefaultPage>
            <Cart />
        </DefaultPage>
    )
}
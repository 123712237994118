import { Header} from "./Header";
import { Footer } from "./Footer";
import styled from "styled-components";
import { useDeviceType } from "../../context/DeviceContext";


interface LayoutProps {
  children: React.ReactNode;
}

export const FullPage = ({ children }: LayoutProps) => {
  const {isMobile} = useDeviceType()
  return (
    <>
    <Header />
    <Body className="default-body" $isMobile={isMobile}>
    {children}
    </Body>
    <Footer />
    </>
    
  );
};


const Body = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  /* align-items: center; // Center content vertically */
  /* width: 100%; */
  min-height: 80vh; // Minimum height to cover the full viewport height

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

import React from "react";
import styled from "styled-components";
import { ActionButton,ButtonStyle } from "../../../../buttons/ActionButton";
import { routesMap } from "../../../../../constants/routes";
import { useSession } from "../../../../../context/SessionContext";
import { Profile } from "./profile";


const StickyButtons: React.FC = () => {

const { session } = useSession();




  return (
    
   
   <>
    {!session.isLogged &&
        <StickyButtonsContainer>
        <ActionButton label="Signup" route={routesMap.signup.to} width="157px" height="48px" style={ButtonStyle.light}/>
    <ActionButton label="Login" route={routesMap.login.to} width="157px" height="48px"/>
    </StickyButtonsContainer>
        }

    {session.isLogged &&
        <StickyUserContainer>
        <Profile/>
    </StickyUserContainer>
        }
   </>
   
  );
};

const StickyButtonsContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 70px;
  gap:20px;
`;

const StickyUserContainer = styled.div`
position: fixed;
display: flex;
justify-content: center;
width: 100%;
gap: 16px;
bottom: 20px;

  
  
  
`;


export default StickyButtons;

import React from "react";


interface AuthProps {
  acsURL: string | null;
  creq: string | null;
}

export const ChallengeIframe: React.FC<AuthProps> = ({ acsURL,creq }) => {
  // Construct the HTML content for the iframe
  const iframeHtml = `
  <html>

  <body>
      <form name="sendChallenge" action="${acsURL}" method="post">
          <input name="creq" value="${creq}" />
      </form>
  </body>
  <script type="text/javascript">
      setTimeout(function() {
          document.createElement('form').submit.call(document.sendChallenge);
      }, 100);
  </script>

  </html>
  `;

  return (
    <iframe 
      id="challenge-iframe" 
      // style={{ display: "none" }} 
      srcDoc={iframeHtml} 
      title="Authentication Frame"
    />
  );
};

import styled from "styled-components";

export const ExcelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 80px;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    flex-flow: column-reverse;
  }
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 28px;
  align-items: flex-start;
`;

export const ImageContainer = styled.div`
  width: 412px;
  margin: auto;
  @media screen and (max-width: 1024px) {
    width: 320px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

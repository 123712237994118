
import { useDeviceType } from "../../context/DeviceContext";
import { FormComponent } from "../form";
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { DevService } from "../../api";
import { useEffect, useState } from "react";


interface PriceListProps{
    serverRes:any
}

interface Map{
    [key:string]:any
}

const map:Map = {
    "startPrice":"Base Price",
    "indicatorPrice": "Indicator",
    "customLogicPrice": "Custom Logic",
    "privatePrice": "Private EA",
}

export const PriceList = ({serverRes}:PriceListProps) => {
  const {isMobile} = useDeviceType()
  const devServices = serverRes?.devServices as DevService[]
  const [orderedDevServices, setOrderedDevServices] = useState<DevService[]>([]);

  const translateNames = (name:string)=>{
    return map[name]

  }


  useEffect(() => {
    if (serverRes) {
        const ordered: DevService[] = [];
        const clone = [...devServices]
        Object.keys(map).forEach(key => {
          const index = clone.findIndex(service => service.name === key);
          if (index !== -1) {
            const [match] = clone.splice(index, 1);
            ordered.push(match);
          }
        });
  
        clone.forEach(service => {
          ordered.push(service);
        });
  
        setOrderedDevServices(ordered);
      }
    
  }, [serverRes]);



 




  
  return (
    <FilterForm $isMobile={isMobile}>
        <FormContainer>
            <Title>
                Price List
            </Title>

            {
                orderedDevServices?.map((service,index)=>{
                    return (<Price key={index}>{translateNames(service.name)}: {service.currency}{service.price.toFixed(2)}</Price>)
                })
            }
        </FormContainer>
    
  </FilterForm>
  );
};


export const FilterForm = styled.div<{$isMobile:boolean}>`
  display: flex;
  justify-content: center;
  border:${prop=>prop.$isMobile?'none':'1px solid '+ Colors.fieldBorder};
  padding: 15px;
  border-radius: 8px;
  background-color: ${Colors.whiteBackground};
  /* width: 100%; */
`;

const FormContainer = styled.div`
display:flex;
flex-direction:column;
align-items: center; /* This will center the items horizontally */
justify-content: center; /* This will center the items vertically and space them evenly */
background-color: ${Colors.whiteBackground};
width: 100%;
margin-bottom: 10px;
`
const Title=styled.h3`
margin: 0;
margin-bottom: 20px;
align-self:flex-start;
`

const Price=styled.div`
margin: 0;
margin-bottom: 10px;
align-self:flex-start;
`







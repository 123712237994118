import styled from "styled-components";
import { Colors } from "../../theme/colors";

import { useDeviceType } from "../../context/DeviceContext";

import React, { useState } from "react";
import { DataProps } from "../data/DataArea";
import { ExpertType,ExpertClassType,TradingType, } from "../../api";
import { Stars } from "../generic/Stars";
import { Icons } from "../../constants/Images";
import {Line} from "../generic/Line"
import {AddToCartButton,ProdcutType} from "../buttons/AddToCartButton"
import { BuyNowButton } from "../buttons/BuyNowButton";
import { DownloadButton } from "../buttons/DownloadButton";
import { Popup } from "./popup";
import { ArrowButton } from "../buttons/ArrowButton";






export const Expert: React.FC<DataProps>= ({data}) => {
    const {isMobile} = useDeviceType()
    const expertData = data as ExpertType;
    const [showPopup,setShowPopup] = useState<boolean>()
    
  

      const getClassName = (value: number): string | undefined => {
        return Object.keys(ExpertClassType).find((key: string) => ExpertClassType[key as keyof typeof ExpertClassType] === value);
      }

      const getTradingTypeName = (value: number): string | undefined => {
        return Object.keys(TradingType).find((key: string) => TradingType[key as keyof typeof TradingType] === value);
      }
  
      return (
        <>   
        <Container>
        <ExpertIcon  $isMobile={isMobile} src={process.env.REACT_APP_API_ENDPOINT + expertData.image}/> 
        {/* <ChartContainer>
            
        </ChartContainer> */}

            <ExpertContainer id="expt-cont">
                <Section className="section" >
                    <LeftContainer>
                    <MainTitle>           
                        <SymbolName>{expertData.name}</SymbolName>
                    </MainTitle>

                    </LeftContainer>

                    <RightContainer>
                    <MainTitle>           
                        <SymbolName>{expertData.price}{expertData.currency}</SymbolName>
                    </MainTitle>
                    </RightContainer>
                    
                </Section> 

                <Section className="section">
                    <Title>
                        <GreyText>{expertData.description}</GreyText>         
                    </Title>
                    
                    
                </Section > 
                <Section>
                <ArrowButton
                    label= {"more info"}
                    color={`${Colors.primary}`}
                    fontSize={14}
                    fontWeight={500}
                    iconHeight={14}
                    iconWidth={14}
                    handleClick={() => setShowPopup(true)}
                    iconUrl={Icons.Arrow}
                    background={`${Colors.whiteBackground}`}
                    />
                    {showPopup &&
                    <Popup
                    setIsVisible={setShowPopup}
                    id={expertData.id}
                    />
                    }
                </Section>
                
                

                
               
               
                <SectionLeft>
                
                    <BulletPoint>
                    <Stars score={expertData.rating} scoreCount={expertData.ratingCount}/>    
                        
                        <Point>
                            <GreyText>{expertData.downloads} downloads</GreyText>         
                        </Point>
                        <Point>
                            <GreyText>{getClassName(expertData.class)} Class</GreyText>         
                        </Point>
                        
                        
                    </BulletPoint>
                    
                </SectionLeft>

                <SectionLeft>           
                        <SubTitle>12 Months Statistics</SubTitle>
                </SectionLeft>
                <ScrollableContainer>
                <BoxSection $isMobile={isMobile}>
                    <Box className="box">
                        <GreyText>Profit&Loss</GreyText>
                        <Value>
                            <BoldText>{(expertData.pl*100).toFixed(2)}%</BoldText>
                            <DirectionIcon src={expertData.pl>0?Icons.GreenUp:Icons.RedDown}/>
                        </Value>
                        
                    </Box>
                    <Box className="box">
                        <GreyText>DrawDown</GreyText>
                        <Value>
                            <BoldText>{(expertData.drawdown*100).toFixed(2)}%</BoldText>
                            <DirectionIcon src={expertData.drawdown>0?Icons.GreenUp:Icons.RedDown}/>
                        </Value>
                    </Box>
                    <Box className="box">
                        <GreyText>Trading Type</GreyText>
                        <BoldText>{getTradingTypeName(expertData.tradingType)}</BoldText>
                    </Box>
                </BoxSection>
                </ScrollableContainer>
                
            <Line></Line>

            <ButtonsSection>
                {!expertData.file && 
                <>
                <AddToCartButton productType={ProdcutType.experts} itemId={expertData.id} />
                <BuyNowButton productType={ProdcutType.experts} itemId={expertData.id} />
                </>
                }
                {expertData.file && 
                <>
                <DownloadButton url={expertData.file} />
                </>
                }
                
            </ButtonsSection>
             
                
            </ExpertContainer>
            <></>
           
            
            
        </Container>   
        
        </>
      );
    };



const BulletPoint = styled.ul`
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
gap: 5px 20px;
padding: 0;
color: ${Colors.text};
`
const Point = styled.li`
margin-left: 10px;
padding: 0;`

const Value = styled.div`
display: flex;
gap:10px;
`


const Container = styled.div`
display: flex;
/* flex-direction: column; */
flex-wrap: wrap;
justify-content:center;
align-items: flex-start;
border: 1px solid ${Colors.fieldBorder};
background-color: ${Colors.whiteBackground};
padding: 20px;
border-radius: 8px;
gap:20px;
`

const ChartContainer = styled.div`

    justify-content: flex-start;
    align-content: flex-start;
`



const Section = styled.div`
display: flex;
justify-content: space-between;
gap:20px;
width: 100%;
`

const ScrollableContainer = styled.div`
  overflow-x: auto;
`;

const BoxSection = styled.div<{$isMobile:boolean}>`
  display: flex;
  padding-bottom: 20px;
  flex: 0 0 auto; 
  gap: 0px 20px;
  margin-right:${(prop=>prop.$isMobile?'-40px':'none')} ;
`;

const Box = styled.div`
  flex: 0 0 128px; 
  border-radius: 8px;
  padding: 12px;
  background-color: ${Colors.messageBox};

  
  &:last-child {
    margin-right: 0; /* Remove margin from the last box */
  }
`;

const SectionLeft = styled.div`
display: flex;
justify-content: flex-start;
gap:20px;
`

const ButtonsSection = styled.div`
display: flex;
justify-content: flex-end;
gap:10px;
`




const LeftContainer=styled.div`
display: flex;
align-items: center;
gap:10px;
`


const RightContainer=styled.div`
padding: 5px;
display: flex;
gap:5px;
`

const Title = styled.div`
display: flex;
flex-direction: column;
/* gap:20px; */
align-items: flex-start;
`


const SymbolName = styled.div`
`

const BoldText = styled.div`
font-weight: 600;
font-size: 14px;
margin-top: 5px;
margin-bottom: 5px;

`
const GreyText = styled.div`
font-size: 14px;
color: ${Colors.text};
`
const MainTitle = styled.div`
display: flex;
justify-content: center;
gap:5px;
color: ${Colors.blackText};
font-size: 18px;
font-weight: 600;
`

const SubTitle = styled.div`
color: ${Colors.blackText};
font-size: 14px;
font-weight: 600;
`





const ExpertContainer = styled.div`
flex: 1;
display: flex;
flex-direction: column;
justify-content: flex-start;
gap:10px;
/* margin-right: 10px; */
`




const ExpertIcon = styled.img<{$isMobile:boolean}>`
box-shadow: 0 0 23px  ${Colors.shadow};
/* border-radius: 50% */
border-radius: 8px;
width: ${(prop=>prop.$isMobile?'100%':'140px')};
height: ${(prop=>prop.$isMobile?'120px':'140px')};
object-fit: cover;
margin:0;
`

const DirectionIcon = styled.img`
width: 16px;
`


import { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import React from "react";
import { FormComponent } from "../form";
import Joi, { string } from "joi";
import { ApiResponse } from "../../api";
import { useSession,Account,CommunicationPreferences } from "../../context/SessionContext";

import { FieldType, FieldConfig, TextFieldType } from "../fields";
import states from 'states-us';
import { Billing } from "../../context/SessionContext";
import { ActionButton ,ButtonStyle} from "../buttons/ActionButton";
import { useFormHandling } from "../../hooks/formUtils";
import { RequestConfigs } from "../../api";
import { CheckBoxFieldOptions,CheckboxField } from "../fields/CheckBoxField";
import { ColorState } from "../fields";



const emails_marketing:FieldConfig = {
  type:FieldType.checkBox,
  name:"emails_marketing",
  label:"Marketing",
  joiSchema:Joi.boolean().required(), 
  typeOptions:{text:"Marketing"}
}

const emails_info:FieldConfig = {
  type:FieldType.checkBox,
  name:"emails_tradingInfo",
  label:"Trading Info",
  joiSchema:Joi.boolean().required(), 
  typeOptions:{text:"Trading Info"}
}

const push_marketing:FieldConfig = {
  type:FieldType.checkBox,
  name:"push_marketing",
  label:"Marketing",
  joiSchema:Joi.boolean().required(), 
  typeOptions:{text:"Marketing"}
}

const push_info:FieldConfig = {
  type:FieldType.checkBox,
  name:"push_tradingInfo",
  label:"Trading Info",
  joiSchema:Joi.boolean().required(), 
  typeOptions:{text:"Trading Info"}
}
const sms_marketing:FieldConfig = {
  type:FieldType.checkBox,
  name:"sms_marketing",
  label:"Marketing",
  joiSchema:Joi.boolean().required(), 
  typeOptions:{text:"Marketing"}
}
const sms_info:FieldConfig = {
  type:FieldType.checkBox,
  name:"sms_tradingInfo",
  label:"Trading Info",
  joiSchema:Joi.boolean().required(), 
  typeOptions:{text:"Trading Info"}
}
  



const defaultCommunication = {
  "emails": {
      "marketing": true,
      "tradingInfo": true
  },
  "sms": {
      "marketing": true,
      "tradingInfo": true
  },
  "push": {
      "marketing": true,
      "tradingInfo": true
  }
}


export const Marketing: React.FC = () => {

  
  const {session} = useSession()
  const [marketingObj,setMarketingObj] = useState<CommunicationPreferences>(session.account?.internalCommunication || defaultCommunication)

  const { handleInputChange, handleClick, serverRes,formValues } = useFormHandling({ requestType:RequestConfigs.updateMarketing,data:marketingObj})

  const createFormComponent = () => {
    
    const onFieldChange = (name:string,value:any)=>{
      const [channel,type]:string[] = name.split("_")
      setMarketingObj((prev) => ({
        ...prev,
        [channel]: {
          ...prev.sms,
          [type]: value,
        },
        
    }));
      

    }

    emails_marketing.defaultValue=session.account?.internalCommunication.emails.marketing
    emails_info.defaultValue=session.account?.internalCommunication.emails.tradingInfo
    push_marketing.defaultValue=session.account?.internalCommunication.push.marketing
    push_info.defaultValue=session.account?.internalCommunication.push.tradingInfo
    sms_marketing.defaultValue=session.account?.internalCommunication.sms.marketing
    sms_info.defaultValue=session.account?.internalCommunication.sms.tradingInfo


    return(
      <>
      <FormComponent fields={[]} title="Marketing Configuration" onChange={()=>{}} serverRes={serverRes} align="flex-start" />
      
      <Section>
      <Text>Emails</Text>
      <CheckboxField fieldConfig={emails_marketing} colorState={ColorState.normal} onFieldChange={onFieldChange}/>
      <CheckboxField fieldConfig={emails_info} colorState={ColorState.normal} onFieldChange={onFieldChange}/>
      </Section>
      <Section>
      <Text>Push Notifications</Text>
      <CheckboxField fieldConfig={push_marketing} colorState={ColorState.normal} onFieldChange={onFieldChange}/>
      <CheckboxField fieldConfig={push_info} colorState={ColorState.normal} onFieldChange={onFieldChange}/>
      </Section>
      
      <Section>
      <Text>SMS</Text>
      <CheckboxField fieldConfig={sms_marketing} colorState={ColorState.normal} onFieldChange={onFieldChange}/>
      <CheckboxField fieldConfig={sms_info} colorState={ColorState.normal} onFieldChange={onFieldChange}/>
      </Section>
      
      <ActionButton onClick={handleClick} label="Submit" width="auto" height="48px"/>
      </>
      
    ) 
  }





  return (
    <>
      <Container id="main-sig">
        {createFormComponent()}
      </Container>

    </>
  );
};




const Container = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content:flex-start;
align-items: flex-start;
width: 100%;
border-radius: 8px;
gap:20px;
`

const Section = styled.div`
display: flex;
flex-direction: column;
gap:10px;
margin-top: -15px;
margin-bottom: 20px;
/* margin:10px 10px; */

`

const Title = styled.h3`
margin-top: -5px;
margin-bottom: 0px;
`

const Text = styled.h4`
margin-top: -8px;
margin-bottom: -4px;
font-size: 16px;
color:${Colors.text}

`





import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../../../theme/colors";
import { Icons } from "../../../../../constants/Images";
import { Link } from "react-router-dom";
import { RoutesSet,MemberRoutesSet, BaseRoute,ParentRouteItem} from "../../../../../modules/routesHelper";
import StickyButtons from "./stickyBottom";
import { useSession } from "../../../../../context/SessionContext";
import { NavLink } from "react-router-dom";
import { routesMap } from "../../../../../constants/routes";





interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  baseRoutes?: BaseRoute[];
}



export const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
  const {session} = useSession()

  const routes =session.isLogged?MemberRoutesSet: RoutesSet

  

  const getCurrentParent = ()=>{

    for(let i in routes){
      const route = routes[i]
      if(route.children){
        const childIsOpen = route.children.filter(child=>child.to===window.location.pathname).length>0
        if(childIsOpen)
          return [route.to]
      }
    }
    return []
    
  }
  

  const [menuOpen, setMenuOpen] = useState<string[]>(getCurrentParent());

  const toggleMenu = (route: string) => {
    if (menuOpen.includes(route)) {
      setMenuOpen(menuOpen.filter(item => item !== route));
    } else {
      setMenuOpen([...menuOpen, route]);
    }
  };
  const renderRoutes = (routes: ParentRouteItem[]) => {
    return routes.map((route, index) => {
      // Check if the current route has children
      const hasChildren = route.children && route.children.length > 0;
      if(route.children && route.children.length > 0){
        const childIsOpen = route.children.filter(child=>child.to===window.location.pathname)
        

        
      }
      
      const isCollapsed = menuOpen.includes(route.to);
  
      // Handle click: Toggle visibility of children or close the sidebar if no children
      const handleClick = (event: React.MouseEvent) => {
        if (hasChildren) {
          event.preventDefault(); // Prevent link navigation for parent items
          toggleMenu(route.to); // Toggle the collapsed state of the menu
        } else {
          onClose(); // Close the sidebar if it's a link without children
        }
      };
  
      return (
        <React.Fragment key={route.name + index}>
          <MenuItem $isActive={window.location.pathname===route.to} onClick={handleClick} to={hasChildren ? '#' : route.to} style={{ cursor: hasChildren ? 'pointer' : 'auto' }}>
            {route.name}
            {hasChildren && (
              <ToggleIcon src={isCollapsed ? Icons.Up : Icons.Down} alt="Toggle" />
            )}
          </MenuItem>
          {hasChildren && isCollapsed && route.children && ( // Add a check for route.children
          <SubMenu>
                {renderRoutes(route.children)} 
          </SubMenu>
            
          )}
        </React.Fragment>
      );
    });
  };

  

  return (
    <SidebarContainer data-isopen={isOpen}>
      <SidebarHeadWrapper>
        <SidebarContent>
          <CartNavLink to={routesMap.cart.to}>
            <Icon src={Icons.Cart} />
            <Text>My Cart{session.cart.length>0 && ` (${session.cart.length})`}</Text>
          </CartNavLink>
          
        </SidebarContent>
        <CloseButton onClick={onClose} />
      </SidebarHeadWrapper>
      <SidebarMenu>{renderRoutes(routes)}</SidebarMenu>
       {isOpen && <StickyButtons/>}
    </SidebarContainer>
  );
};

const CartNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none; 
`;

const SidebarContainer = styled.div<{ 'data-isopen': boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: ${({ 'data-isopen': isOpen }) => (isOpen ? "0" : "-100%")};
  width: 100%;
  height: 100%;
  background-color: ${Colors.whiteBackground};
  z-index: 1000;
  transition: left 0.3s ease-in-out;
`;

const SidebarHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
  height: 64px;
  box-shadow: 0px 4px 4px 0px ${Colors.shadow};
  margin-bottom:10px;
`;

const SidebarContent = styled.div`
  display: flex;
  align-items: center;
`;

const CloseButton = styled.button`
  width: 36px;
  height: 36px;
  background-color: transparent;
  background-image: url(${Icons.X});
  background-size: cover;
  border: none;
  cursor: pointer;
`;

const SidebarMenu = styled.div`
padding-left: 30px;
`;

const MenuItem = styled(Link)<{ $isActive: boolean }>`
  display: flex; /* Change to block to ensure each item appears on a new line */
  justify-content: space-between;
  width: fit-content; 
  position: relative;
  align-items: center;
  padding: 16px 0px;
  color: ${Colors.text};
  text-decoration: none;
  font-size: 14px;
  
  

  &:hover {
    background-color: ${Colors.whiteBackground};
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${(props) => (props.$isActive ? Colors.primary : "transparent")};
  }
  
`;



const SubMenu = styled.div`
margin-left: 20px;
font-weight: 500;
color:red;
`;

const ToggleIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const Text = styled.p`
  margin: 0;
`;

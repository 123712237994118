import { Header} from "./Header";
import { Footer } from "./Footer";
import styled from "styled-components";
import { useDeviceType } from "../../context/DeviceContext";
import { Backgrounds } from "../../constants/Images";


interface LayoutProps {
  children: React.ReactNode;
}

export const DefaultPage = ({ children }: LayoutProps) => {
  const {isMobile} = useDeviceType()
  return (
    <Container>
    
      <Header />
    
    

    
    <Body className="default-body" $isMobile={isMobile}>
      
    {children}
    </Body>
  
    <Footer />
    
    </Container>
    
  );
};

const Container = styled.div`
 display: flex;
 position: relative;
 flex-direction: column;
`


const Wrapper = styled.div`
display: flex;
flex-direction: column;
background-image: url(${Backgrounds.MainBackground});
background-size: cover;
background-position: relative; // Ensure the background is centered
`

const Body = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  position: relative;
  background-image: url(${Backgrounds.MainBackground});
background-size: cover;
background-position: relative; 
  
  /* min-height: 80vh; */
  padding-left: ${props => props.$isMobile ? 'none' : '120px'};
  padding-right: ${props => props.$isMobile ? 'none' : '120px'};
  padding-top: ${props => props.$isMobile ? 'none' : '28px'};
  padding-bottom: 28px;
  

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { CarouselContainer } from "../index.style";
import { KTText } from "../../Text";
import { Colors } from "../../../theme/colors";
import { ArrowButton } from "../../buttons/ArrowButton";
import { Icons, Images } from "../../../constants/Images";
import { useSwiper } from "swiper/react";
import { Image } from "../../Image";
import { useDictionary } from "../../../context/DictionaryContext";
import {
  ArrowArea,
  CarouselWrapper,
  CustomImage,
  FullName,
  ImageContainer,
  Intro,
  StarArea,
  TextArea,
  TitleContainer,
} from "./index.style";
import { useState } from "react";

const sliderData = [
  {
    source: Images.Avatar1,
    firstName: "home_testimonials_avatar1_fn",
    lastName: "home_testimonials_avatar1_ln",
    des: "home_testimonials_avatar1_desc",
    star: 5,
    width: 360,
    top: 0,
    right: 20,
    hover: false,
  },
  {
    source: Images.Avatar2,
    firstName: "home_testimonials_avatar2_fn",
    lastName: "home_testimonials_avatar2_ln",
    des: "home_testimonials_avatar2_desc",
    star: 5,
    width: 270,
    top: 15,
    right: 20,
    hover: false,
  },
  {
    source: Images.Avatar3,
    firstName: "home_testimonials_avatar3_fn",
    lastName: "home_testimonials_avatar2_ln",
    des: "home_testimonials_avatar2_desc",
    star: 5,
    width: 250,
    top: 0,
    right: 20,
    hover: false,
  },
];

export const BestTraders = () => {
  const swiper = useSwiper();
  const [testimonials, setTestimonials] = useState(sliderData);
  const {translate} = useDictionary()

  for(let i in sliderData){
    sliderData[i].firstName = translate(sliderData[i].firstName)
    sliderData[i].lastName = translate(sliderData[i].lastName)
    sliderData[i].des = translate(sliderData[i].des)
  }

  const handleHover = (index: number) => {
      let data = [...testimonials];
      data[index].hover = true;
      setTestimonials(data);
  };
  const handleLeave = (index: number) => {
      let data = [...testimonials];
      data[index].hover = false;
      setTestimonials(data);
  };
  return (
    <CarouselContainer>
      <CarouselWrapper>
        <TitleContainer>
          <KTText
            fontWeight={500}
            fontSize={24}
            lineheight={28}
            color={`${Colors.blackBackground}`}
          >
           {translate("home_testimonials_title")}
          </KTText>
          <ArrowArea>
            <ArrowButton
              label=""
              iconHeight={12}
              width={"44px"}
              height={44}
              iconWidth={14}
              boxshadow={"0px 0px 32px 0px #C3C3C340"}
              borderradius={50}
              background={Colors.whiteBackground}
              iconUrl={`${Icons.LeftArrow}`}
              
            />
            <ArrowButton
              label=""
              width={"44px"}
              background={Colors.primary}
              height={44}
              iconHeight={14}
              borderradius={50}
              iconWidth={14}
              iconUrl={`${Icons.RightArrowWhite}`}
              
            />
          </ArrowArea>
        </TitleContainer>{" "}
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {testimonials.map((ele, id) => (
            <SwiperSlide
              key={id}
              onMouseEnter={() => handleHover(id)}
              onMouseLeave={() => handleLeave(id)}
            >
              {ele.hover ? (
                <Intro>
                  <TextArea>
                    <FullName>
                      <KTText
                        fontWeight={500}
                        fontSize={20}
                        textalign="left"
                        lineheight={28}
                        color={Colors.primary}
                      >
                        {ele?.lastName}
                      </KTText>
                      <KTText
                        fontWeight={500}
                        fontSize={20}
                        textalign="left"
                        lineheight={28}
                      >
                        {ele?.firstName}
                      </KTText>
                    </FullName>
                    <KTText
                      fontWeight={400}
                      color={Colors.gray}
                      textalign="left"
                      fontSize={14}
                      lineheight={20}
                    >
                      {ele?.des}
                    </KTText>
                    <StarArea>
                      {[...new Array(5)].map(() => (
                        <Image src={Images.Star} width={14} height={14} />
                      ))}
                    </StarArea>
                  </TextArea>
                  <CustomImage
                    src={ele.source}
                    width={ele.width}
                    top={ele.top}
                    right={ele.right}
                  />
                </Intro>
              ) : (
                <ImageContainer>
                  <CustomImage
                    src={ele.source}
                    width={ele.width}
                    top={ele.top}
                    right={ele.right}
                  />
                </ImageContainer>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </CarouselWrapper>
    </CarouselContainer>
  );
};

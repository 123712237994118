import React from 'react'
import { ForgotPassword } from '../components/registrations'
import { AuthPage } from '../components/Layout/AuthPage'

export const ForgotPasswordPage: React.FC = () => {
    return (
        <AuthPage>
            <ForgotPassword />
        </AuthPage>
    )
}
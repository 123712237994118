import styled from "styled-components";
import { Colors } from "../../theme/colors";
import React from "react";
import { DataProps } from "../data/DataArea";
import { RemoveFromCartButton } from "../buttons/RemoveFromCartButton";
import { CartItem as CartItemType } from "../../context/SessionContext";




export const CartItem: React.FC<DataProps>= ({data,index}) => {
    const cartItem = data as CartItemType
    
  
    if(index===undefined)
        return (<></>)

      return (
        <>   
        <Container id="main-sig">
           <Section>
                <Icon src={process.env.REACT_APP_API_ENDPOINT + cartItem.previewImage}/>

                <Content>
                    <Title>{cartItem.name}</Title>
                    <SubTitle>{cartItem.currency}{cartItem.price} | {cartItem.typeName}</SubTitle>
                </Content>
            
           </Section>
           <ButtonSection>
            <RemoveFromCartButton location={index}/>
           </ButtonSection>
        </Container>   
        
        </>
      );
    };




const Container = styled.div`
display: flex;
/* flex-direction: column; */
flex-wrap: wrap;
justify-content:space-between;
background-color: ${Colors.whiteBackground};
align-items: center;
border: 1px solid ${Colors.fieldBorder};
padding: 20px;
border-radius: 8px;
gap:20px;

`
const Icon = styled.img`
width: 42px;
border-radius: 8px;
`

const Section = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap:20px;
`
const ButtonSection = styled.div`
display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto; /* Aligns content to the right */`

const Content = styled.div`
display: flex;
flex-direction: column;
`

const Title = styled.div`
font-size: 16px;
color: ${Colors.blackText};
font-weight: 500;
`
const SubTitle = styled.div`
font-size: 14px;
color: ${Colors.text};
font-weight: 400;
`

    
import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/colors'; // Ensure this path is correct and Colors.text is defined

interface TextLineProps {
  children?: React.ReactNode;
  width?:string
}

// Styled component for the line with text
const LineWithText = styled.div<{width?:string}>`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: ${Colors.text}; // Make sure Colors.text is a valid color string
  width: ${(props)=>props.width || "100%"}; // Corrected from "100%" to 100%

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${Colors.fieldBorder}; // Ensure this is visible
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
`;

// React component with TypeScript
export const TextLine: React.FC<TextLineProps> = ({ children,width }) => (
  <LineWithText width={width}>
    {children}
  </LineWithText>
);


import { Pagination } from "./paging";
import { useDeviceType } from "../../context/DeviceContext";
import { PagingObj } from "../../api";
import React from "react";
import { SignalType,ExpertType,CourseType } from "../../api";
import { Body,DataContainer } from "./index.style";
import { CartItem } from "../../context/SessionContext";


export interface DataProps {
  data:SignalType | CartItem | ExpertType | CourseType
  index?:number
}

interface DataAreaProps {
  handlePage:(page:number)=>void
  data?: any[]
  paging?: PagingObj
  Element:React.ComponentType<DataProps>
}

export const DataArea = ({handlePage,data,paging,Element}: DataAreaProps) => {
  const {isMobile} = useDeviceType()
  return (
    <DataContainer id="data-container" $isMobile={isMobile}>
      <Body id="body-container">
        <>
        {
        data && data.map((item,index) => (
          <Element data={item} key={index}/>

        ))}

        </>
        
      </Body>
    <Pagination pagingObj={paging} onPageChange={handlePage} />
  </DataContainer>
  );
};







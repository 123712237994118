import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { ApiResponse, SignalType, Statistics } from "../../api";
import { Icons } from "../../constants/Images";
import { useDeviceType } from "../../context/DeviceContext";
import { useState } from "react";
import { routesMap } from "../../constants/routes";
import React from "react";
import { DataProps } from "../data/DataArea";

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine ,
  } from 'recharts';



  interface PromoProps {
    serverRes:ApiResponse
  }

export const Promo: React.FC<PromoProps>= ({serverRes}) => {
  const {isMobile} = useDeviceType()
  const statistics = serverRes?.statistics as Statistics
      return (
        <>   
        <Container $isMobile={isMobile}>
       

      
     
            <SignalContainer id="sig-cont" $isMobile={isMobile}>
            {/* <BoxContainer> */}
                  <Box className="box">
                      <GreyText>Total Signals</GreyText>
                      <BoldText>{statistics?.totalSignals}</BoldText>
                  </Box>
                  <Box className="box">
                      <GreyText>Winning Signals</GreyText>
                      <BoldText>{(statistics?.winRate*100).toFixed(2)+"%"}</BoldText>
                  </Box>

                  <Box className="box">
                      <GreyText>Profit in pips</GreyText>
                      <BoldText>{Math.round(statistics?.winPips/1000)+"K"}</BoldText>
                  </Box>

            {/* </BoxContainer> */}
            </SignalContainer>
          
            
          
            <ChartContainer >
            <ResponsiveContainer>
                <LineChart data={statistics?.chart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                 dataKey="date" 
                 stroke="none"
                 tick={{ fontSize: 12, fill: Colors.text }}
                //  label={{ value: 'Date', position: 'insideBottomRight', offset: -5, fill: '#84d8a4' }}
                 />
                <YAxis 
                    stroke="none"
                    tick={{ fontSize: 12, fill: Colors.text }}
                />
                <Tooltip />
                <Line type="monotone" dataKey="profit" stroke={Colors.primary} strokeWidth={3} />
                <ReferenceLine y={0} stroke={Colors.text}strokeWidth={2} strokeDasharray="5 5" />
                </LineChart>
            </ResponsiveContainer>
            </ChartContainer>
                
       
            <></>
          
            
            
        </Container>   
        
        </>
      );
    };
const Container = styled.div<{$isMobile:boolean}>`
display: flex;
/* flex-direction: column; */
background-color: ${Colors.whiteBackground};
flex-wrap: wrap;
justify-content:space-between;
align-items: center;
border:${props=>props.$isMobile?'none':`1px solid ${Colors.fieldBorder}`};
padding: 20px;
border-radius: 8px;
gap:20px;
`

const ChartContainer = styled.div`
flex: 1;
display: flex;
justify-content: center;
width: 100%;
height: 400px;
margin-left: -20px;
`

const SignalContainer = styled.div<{$isMobile:boolean}>`
    padding: 20px;
    
    display: flex;
    flex-direction: column;
    width: ${props=>props.$isMobile?'100%':'20%'};
    gap:20px;
    
    justify-content: center;
    /* margin-right: 10px; */
    
    /* margin: auto; */
`


const Box = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: auto;
border-radius: 8px;
padding: 12px;
background-color:${Colors.primary};
/* align-items: center; */
`





const BoldText = styled.div`
font-weight: 600;
font-size: 30px;
margin-top: 5px;
margin-bottom: 5px;
color: ${Colors.whiteBackground};

`
const GreyText = styled.div`
font-size: 14px;
color: ${Colors.whiteBackground};
`









    
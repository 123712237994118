import React, {useEffect,useRef } from "react";
import styled from "styled-components";
import { Colors } from "../../../../theme/colors";
import { NavLink } from "react-router-dom";
import { BaseRoute,ParentRouteItem} from "../../../../modules/routesHelper";
import { AccountRoutes,accountRoutesMap } from "../../../../constants/routes";





interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  baseRoutes?: BaseRoute[];
}



export const AccountMenu: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
 
  const routes = AccountRoutes
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen &&  menuRef.current && !menuRef.current.contains(event.target as Node)) {
         onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose,isOpen]);


  
  const renderRoutes = (routes: ParentRouteItem[]) => {
    return routes.map((route, index) => {
      if (route.to===accountRoutesMap.account.to)
        return null
      const handleClick = (event: React.MouseEvent) => {
        onClose();
      };
  
      return (
        <React.Fragment key={route.name + index}>
            <MenuItem onClick={handleClick} to={route.to}>
            {route.name}
          </MenuItem>
          
        </React.Fragment>
      );
    });
  };

  

  return (
    <SidebarContainer $isOpen={isOpen} ref={menuRef}>
        <SidebarMenu>{renderRoutes(routes)}</SidebarMenu>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 60px;
  right: ${({ $isOpen: isOpen }) => (isOpen ? "7%" : "-100%")};
  box-shadow: 0px 0px 5px ${Colors.primary};
  width: 320;
  background-color: ${Colors.whiteBackground};
  z-index: 1000;
  transition: top 0.9s ease-in-out;
`;



const SidebarMenu = styled.div`
`;

const MenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  color: ${Colors.text};
  text-decoration: none;
  font-size: 14px;
  cursor: pointer; /* Set cursor to pointer */

  &:hover {
    color: ${Colors.primary};
    background-color: ${Colors.whiteBackground};
  }
`;



import styled from "styled-components";
import { Image } from "../Image";
import { Colors } from "../../theme/colors";

interface ArrowButtonProps {
  label: string;
  background?: string;
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  width?: string;
  height?: number;
  borderradius?: number;
  handleClick?: () => void;
  iconWidth: number;
  iconHeight: number;
  iconUrl: string;
  boxshadow?: string;
}

export const ArrowButton: React.FC<ArrowButtonProps> = (props) => {
  const {
    label,
    background,
    color,
    fontSize,
    fontWeight,
    width,
    height,
    borderradius,
    handleClick,
    iconUrl,
    iconHeight,
    iconWidth,
    boxshadow,
  } = props;
  return (
    <StyledButton
      background={background}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      width={width}
      height={height}
      borderradius={borderradius}
      boxshadow={boxshadow}
      onClick={handleClick}
    >
      {label}
      <Image src={iconUrl} height={iconHeight} width={iconWidth} />
    </StyledButton>
  );
};

export const StyledButton = styled.button<{
  background?: string;
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  width?: number | string;
  height?: number;
  borderradius?: number;
  boxshadow?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  cursor: pointer;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderradius}px;
  box-shadow: ${(props) => props.boxshadow};
  padding: ${(props) => props.background===Colors.primary?"10px":0};
`;

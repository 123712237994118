import { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import React from "react";
import { FormComponent } from "../form";
import Joi from "joi";
import { ApiResponse } from "../../api";
import { useSession,Account } from "../../context/SessionContext";

import { FieldType, FieldConfig, TextFieldType } from "../fields";
import states from 'states-us';
import { Billing } from "../../context/SessionContext";
import { ActionButton ,ButtonStyle} from "../buttons/ActionButton";
import { useFormHandling } from "../../hooks/formUtils";
import { RequestConfigs } from "../../api";


const fields: FieldConfig[] = [
  {
    type: FieldType.text,
    name: "name",
    label: "Full Name",
    joiSchema: Joi.string().min(4).required(),
    typeOptions: { type: TextFieldType.text }
  },
  {
    type: FieldType.text,
    name: "emailAddress",
    label: "Email",
    joiSchema: Joi.string().min(4).required(),
    typeOptions: { type: TextFieldType.text }
  },
  {
    type: FieldType.phone,
    name: "phoneNumber",
    label: "Mobile Phone",
    joiSchema: Joi.string().min(5).max(13).required(),
    typeOptions: { countryFieldName: "country" }
  },


]





export const PersonalInfo: React.FC = () => {

  const { handleInputChange, handleClick, serverRes,formValues } = useFormHandling({ requestType:RequestConfigs.updateAccount})

  const { session } = useSession()

  const [isEditMode,setIsEditMode] = useState(!session.billing?.city)

  const account = session.account as Account



  const checkCountry = (name: string, value: any, valid: boolean) =>{
    if(name==="country" && value==="us" ){
      fields[2].hide=false
    } else if(name==="country"){
      fields[2].hide=true
    }
    handleInputChange(name,value,valid)

  }


 
  
  


  const createFormComponent = () => {
    
    for (let i in fields) {
      const fieldName = fields[i].name as keyof Account;
      if (account.hasOwnProperty(fieldName) && account[fieldName]) {
        fields[i].defaultValue = account[fieldName];
      }
      
    }
    return(
      <>
      <FormComponent fields={fields} title="Personal Info" onChange={checkCountry} serverRes={serverRes} align="flex-start" />
      <ActionButton onClick={handleClick} label="Submit" width="auto" height="48px"/>
      </>
      
    ) 
  }





  return (
    <>
      <Container id="main-sig">
        {createFormComponent()}
      </Container>

    </>
  );
};




const Container = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content:flex-start;
align-items: flex-start;
width: 100%;
border-radius: 8px;
gap:20px;
`

const Head = styled.div`
display: flex;
flex-wrap: wrap;
justify-content:space-between;
align-items: center;
width: 100%;
`

const Title = styled.h3`
margin-top: -5px;
margin-bottom: 0px;
`

const Text = styled.p`
margin-top: -8px;
margin-bottom: -8px;
font-size: 16px;
color:${Colors.text}

`





// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import {getMessaging,onMessage,getToken, Messaging  } from "firebase/messaging";



const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};





export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
let messaging:Messaging | null = null
try {
  messaging = getMessaging(app);
} catch (e){
  console.log(e,"errrr")
}



export {
  messaging,
  getToken
}




export const auth = getAuth(app);
export default app;




